import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    selected_search_ids: [],
    avidoExerciseList: [],
    result_type: null,
    wfc_type: null,
    selected_id: 0,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    unsetavidoExercise(state) {
      state.selected_search_ids=[]
      state.avidoExerciseList=[]
      state.result_type=null
      state.wfc_type=null
    },     
    setavidoExercise(state,{response_data,resulttype}) {
      state.avidoExerciseList=response_data
      state.result_type=resulttype
    }
  },

  actions: {
    getAvidoExercise({ commit,dispatch },resulttype) {
      if(this.state.bottomSearch.avidoExerciseList.length==0){
        commit("unsetavidoExercise");
        return new Promise((resolve,reject) => {
          axios({
            method: 'GET',
            url: 'get_search_exercise_list'
          })
            .then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                const response_data = resp.data.response
                commit("setavidoExercise",{response_data,resulttype});
                router.push({ path: '/bottom-search-exercise' });
                resolve(resp)
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
      }else{
        this.state.bottomSearch.result_type=resulttype
        router.push({ path: '/bottom-search-exercise' });
      }
    },
  }
};
<template>
<div class="joinTeam">
 <v-row justify="center">
    <v-dialog
      v-model="joindialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card flat class="pa-8">
       <v-toolbar dark color="black">
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="avidocolor--text">Join Team</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
              
          <v-tabs v-model="tab"
            background-color="black"
            color="avidocolor"
            centered
            icons-and-text
            fixed-tabs
            show-arrows
           >
            <v-tab key="athlete">Athlete</v-tab>
            <v-tab key="coach">Coach</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
              <v-form
                ref="jointeamform"
                v-model="valid"
                lazy-validation
                @submit.prevent="onSubmit"
                method="post"
              >
                <v-tab-item key="athlete">
                  <v-text-field
                    v-model="athlete_join_code"
                    class="pa-md-4"
                    prepend-icon="mdi-pencil"
                    name="athlete_join_code"
                    label="Enter Athlete Join Code"
                    color="avidocolor"
                    :rules="[(v) => !!v || 'Athlete Join Code is required']"
                  ></v-text-field>
                </v-tab-item>

                <v-tab-item key="coach">
                  <v-text-field
                    v-model="coach_join_code"
                    class="pa-md-4"
                    prepend-icon="mdi-pencil"
                    name="coach_join_code"
                    label="Enter Coach Join Code"
                    color="avidocolor"
                    :rules="[(v) => !!v || 'Coach Join Code is required']"
                  ></v-text-field>
                </v-tab-item>

                <div class="clear"></div>
                <v-footer color="black" fixed padless>
                  <v-row justify="center" no-gutters>
                    <v-btn  type="submit" class="my-2 avidocolor--text" text >
                      <v-icon>mdi-plus-box</v-icon>Join Now
                    </v-btn>
                  </v-row>
                </v-footer>
              </v-form> 
          </v-tabs-items>
      </v-card>  
    </v-dialog>
  </v-row>
</div>  
</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "JoinTeam",
  data: () => ({
    tab: '',
    header_name: '',
    valid: false,
    covalid: false,
  }),
  computed:{
    ...mapFields('teamProfile',{
      joindialog:'joindialog',
      coach_join_code:'coach_join_code',
      athlete_join_code:'athlete_join_code',
    })
  },
  methods: {
    ...mapActions('teamProfile',{
      joinTeam:'joinTeam',      
    }),
    closeDialog(){
      this.joindialog=false
    },
    onSubmit() {
      if(this.athlete_join_code!=null || this.coach_join_code!=null){
        this.joinTeam();
      }else{
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  watch:{
    joindialog:function(){
      if(this.joindialog==false){
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.joindialog=true
    this.$store.commit("teamProfile/resetModelState");
  }
};
</script>
<template>
  <div>
    <v-app-bar
      color="black"
      dense
      dark
    > 
      <v-app-bar-nav-icon  @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>

      <!-- Not Display on large and extra large screen-->
      <!-- <v-spacer class="d-lg-none d-xl-none"></v-spacer> -->
      <!-- <v-toolbar-title class="avidocolor--text">{{header_title}}</v-toolbar-title> -->

      
      <template v-if="header_title!==''">
        <v-spacer></v-spacer>
        <v-row align="center" justify="center">
          <v-col>
            <h2 class="d-none d-lg-block avidocolor--text text-no-wrap">{{header_title}}</h2>
            <h4 class="d-lg-none avidocolor--text text-no-wrap">{{header_title}}</h4>
            <v-divider class="white"></v-divider>
          </v-col>
        </v-row>
      </template>
      <v-toolbar-side-icon name="side-icon" v-else>
        <v-img alt="Avido" width="120" src="/images/logo_with_name.png" />
      </v-toolbar-side-icon>  



      <v-spacer></v-spacer>
      <!-- Display only on large and extra large screen-->
      <div  v-if="!isTokenActive" id="nav" class="d-none d-lg-flex d-xl-flex">
        <router-link 
        v-for="(nitem, index) in header_rnavigation_items"
        :key="nitem.title"
        :to="nitem.link"
        >
        {{ nitem.title }}
         <span class="pa-2 white--text"  v-if="index < header_rnavigation_items.length - 1" :key="index">|</span>
        </router-link> 
      </div> 

      <v-btn  v-if="!isTokenActive" icon to="/login" class="d-lg-none d-xl-none avidocolor--text">
        <v-icon>mdi-login</v-icon> 
      </v-btn>

      <v-btn v-if="isTokenActive && component_name!='USERPROFILE'" icon to="/user-profile" class="avidocolor--text">
        <v-icon>mdi-account-circle</v-icon> 
      </v-btn>

      <v-btn v-if="isTokenActive && component_name=='USERPROFILE'" icon to="/user-search" class="avidocolor--text">
        <v-icon>mdi-magnify</v-icon> 
      </v-btn> 

    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        class="height-app"
        temporary
        app 
      >
        <v-list-item  v-if="isTokenActive" to="/user-profile">
          <v-list-item-avatar tile size=50 >
            <v-img class="rounded"  :alt="user_name" :src="(user_profile_pic!=null)? user_profile_pic: 'images/logo.jpg'" @error="user_profile_pic='images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title  class="avidocolor--text text-left"><h3>{{ user_name }}</h3></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  v-else to="/">
          <v-list-item-title><v-img alt="Avido" width="120" src="/images/logo_with_name.png" /></v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
        
        <v-list dense>
          <v-list-item
            v-for="item in navigation_items"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title  class="text-left">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item  v-if="isTokenActive">
            <v-list-item-icon>
              <v-icon class="avidocolor--text">mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title  class="avidocolor--text text-left"><h3>{{ user_name }}</h3></v-list-item-title>
              <v-list dense>
                <v-list-item
                  v-for="uitem in useritems"
                  :key="uitem.title"
                  :to="uitem.link"
                  class="pa-0"
                >
                  <v-list-item-icon  class="mr-4">
                    <v-icon>{{ uitem.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-left">{{ uitem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isTokenActive && upgrade_to_trainer==0" >
            <v-list-item-content>
              <v-list-item-title>
                <v-btn large block color="avidocolor" @click="upgradeToTrainer()"> Upgrade To Trainer </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

     </v-navigation-drawer>
  </div>
</template>

<script>

import { mapState,mapGetters } from "vuex";
import axios from "axios";
import { mapFields } from "vuex-map-fields";

  export default {
    computed:{
      ...mapState(['header_title','component_name']),
      ...mapGetters('user',{
        isTokenActive:'isTokenActive',
        user_name:'user_name',
      }),
      ...mapFields('user',{
        upgrade_to_trainer:'upgrade_to_trainer',
        user_profile_pic:'user_profile_pic',
      }),
    },
    data () {
      return {
        drawer: null,
        header_rnavigation_items: [
          // { title: 'Home', link: '/', icon: 'mdi-home' },
          { title: 'Athletes', link: '/' , icon: 'mdi-run'},
          { title: 'Coaches', link: '/coaches', icon: 'mdi-account-multiple' },
          { title: 'Login', link: '/login' , icon: 'mdi-login'},
        ],
        navigation_items: [
          { title: 'Home', link: '/', icon: 'mdi-home' },
          { title: 'Athletic Directors', link: '/#', icon: 'mdi-human-male' },
          { title: 'Coaches', link: '/coaches', icon: 'mdi-account-multiple' },
          { title: 'Login', link: '/login' , icon: 'mdi-login'},
          { title: 'FAQ', link: '/faq', icon: 'mdi-comment-question-outline' },
          { title: 'Contact Us', link: '/contactus', icon: 'mdi-phone-in-talk' },
        ],
        useritems: [
          { title: 'My Profile', link: '/user-profile', icon: 'mdi-account' },
          { title: 'My Workouts', link: '/week-summary', icon: 'mdi-calendar-clock' },
        //  { title: 'Workout Exercise', link: '/workoutexercise', icon: 'mdi-calendar-clock' },
        //  { title: 'Workout Exercise Equipment', link: '/workoutequipment', icon: 'mdi-calendar-clock' },
        //  { title: 'Workout Exercise Set', link: '/workoutexerciseset', icon: 'mdi-calendar-clock' },
          { title: 'My Results', link: '/results', icon: 'mdi-chart-bar' },
          { title: 'Training Library', link: '/library', icon: 'mdi-library' },
          { title: 'Training Preferences', link: '/trainingpreferences', icon: 'mdi-dumbbell' },
          { title: 'My Teams & Trainers', link: '/myteamtrainers', icon: 'mdi-account-group' },
          { title: 'Account Settings', link: '/accountsettings', icon: 'mdi-account-settings' },
          { title: 'Profile Settings', link: '/profilesettings', icon: 'mdi-settings' },
          { title: 'Change Password', link: '/changepassword', icon: 'mdi-lock-reset' },
          { title: 'Logout', link: '/logout', icon: 'mdi-logout-variant' },
        ],
      }
    },
    methods: {
      upgradeToTrainer: function () {
          axios.post(`upgrade_to_trainer`).then((resp) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: resp.data.message,
              alertClass: "success",
            });
            this.$refs.chnagepasswordform.reset();
          }).catch((error) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: error.response.data.errors,
              alertClass: "error",
            });
          });
      },
    }
  }
</script>
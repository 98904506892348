import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    current_calendar_option: [],
    program_type_option: [],
    no_workouts_per_week_option:  [],
    goal_option: [],
    sport_option: [],
    equipment_option: [],
    team_id: 0,
    name: '',
    team_calendar_type: '',
    program_type: '',
    program_start_date: '',
    sport_inseason_startdate: '',
    sport_inseason_enddate: '',
    goal_end_date: '',
    no_of_workout_per_week: 0,
    goal_sport_id: 0,
    team_equipments: [],
  },

  getters: {
    getField,
  },

  mutations: {
    setTrainingModelState(state, model_data) {
      
      state.name = model_data.team_name
      state.team_calendar_type = model_data.team_calendar_type
      state.program_start_date = model_data.program_start_date
      state.sport_inseason_startdate = model_data.sport_inseason_startdate
      state.sport_inseason_enddate = model_data.sport_inseason_enddate
      state.goal_end_date = model_data.goal_end_date
      state.no_of_workout_per_week = model_data.no_of_workout_per_week
      state.program_type = (model_data.program_type_id>0) ? model_data.program_type : null
     
      if(model_data.program_type_id>0){
        if(typeof(model_data.program_type_name) != "undefined" && model_data.program_type_name !== null) {
          state.goal_sport_id={
              id : model_data.program_type_id,
              name: model_data.program_type_name
          }
        }
      }

      if(model_data.team_equipments!=null){
        state.team_equipments=[];
        for (let value of Object.values(model_data.team_equipments)) {
          if(typeof(value.name) != "undefined" && value.name !== null) {
            state.team_equipments.push(value.id)
          }
        }
      }
    },

    setAvidoOptionsState(state, option_data) {
      
      state.current_calendar_option=option_data.TEAM_CALENDAR_TYPE
      state.program_type_option=option_data.PROGRAM_TYPE
      state.no_workouts_per_week_option=option_data.WORKOUT_PER_WEEK
      state.equipment_option=option_data.EQUIPMENT
      state.goal_option=option_data.GOAL

      if(option_data.SPORT!=null){
        for (let value of Object.values(option_data.SPORT)) {
          if(typeof(value.sport_positions) != "undefined" && value.sport_positions !== null) {            
            state.sport_option.push({
                id : value.id,
                name: value.name
            })
          }
        }
      }
      
    },
    updateField,

  },

  actions: {
    
    getTrainingModel({ dispatch,commit }) {
      return new Promise((resolve,reject) => {

        let one = "get_avido_options"
        let two = "view_team_detail?team_id="+this.state.teamTrainingPreferences.team_id

        const requestOne = axios.get(one);
        const requestTwo = axios.get(two);
        const requestArray = [requestOne]
        
        if(this.state.teamTrainingPreferences.team_id>0){
          requestArray.push(requestTwo)
        }
        
        axios.all(requestArray).then(axios.spread((...responses) => {
          const responseOne = responses[0]
          const avido_options_resp_code = responseOne.status
          if (avido_options_resp_code == 200) {
            const options_data = responseOne.data.response
            commit("setAvidoOptionsState", options_data);
          } 

          const responseTwo = responses[1]
          const training_resp_code = responseTwo.status
          if (training_resp_code == 200) {
            const model_data = responseTwo.data.response
            commit("setTrainingModelState", model_data);
          } 

          resolve(responses)
        })).catch(error => {
          const resp_code = error.response.status
          if (resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },
    
    updateTrainingModel({ dispatch }) {
      return new Promise((resolve, reject) => {
          let model=this.state.teamTrainingPreferences
          axios({
            method: 'POST',
            url: 'add_edit_team',
            data: {
              team_id:  model.team_id,
              team_calendar_type:  model.team_calendar_type,
              no_of_workout_per_week:  model.no_of_workout_per_week,
              team_equipments:  model.team_equipments.join(','),
              goal_sport_id: (model.goal_sport_id instanceof Object)? model.goal_sport_id.id : model.goal_sport_id,
              sport_inseason_startdate:  model.sport_inseason_startdate,
              sport_inseason_enddate:  model.sport_inseason_enddate,
              goal_end_date:  model.goal_end_date,
              name:  model.name,
            }
          })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
 
              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });

              router.push({ path: '/myteamtrainers' });

              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
  },

};
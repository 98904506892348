<template>
<div class="MyTeamTrainers">
  <template v-if="my_organization_ascoach.length>0">

    <v-card class="mt-8">  
      <v-fab-transition>
        <v-btn
          color="avidocolor black--text"
          dark
          absolute
          top
          right
          fab
          small
          @click=createteam()
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-toolbar color="black">
        <v-row align="center" justify="center">
          <v-col cols="12" md="3">
            <h2 class="avidocolor--text">My Teams</h2>
            <v-divider class="white"></v-divider>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-list two-line v-if="my_teams_ascoach.length>0">
        <template v-for="(team,tindex) in my_teams_ascoach">
        <v-list-item
          :key="tindex"
          @click="getteamprofile(team)"
        >

          <v-list-item-avatar tile size=50>
            <v-img class="rounded" alt="team.name" :src="(team.profile_pic!=null)?team.profile_pic:'/images/logo.jpg'"  @error="team.profile_pic='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="text-left align-self-start">
            <v-list-item-title v-text="team.name"></v-list-item-title>
            <v-list-item-subtitle>
              <span   @click="getteamtrainingprefrences(team.id)" class="subtitle-1 font-weight-light avidocolor--text"> 
                Training Preference 
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-row>  
              <v-col class="pa-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click.stop.prevent="
                        editteam(team.id)
                      "
                    >
                      <v-icon color="avidocolor"
                        >mdi-pencil-box-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit This Team</span>
                </v-tooltip>
              </v-col>
              <v-col class="pa-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click.stop.prevent="leaveTeamAsCoachModel({'remove_index':tindex,'team_id':team.id,'delete_coach_user_ids':coach_user_id})"
                    >
                      <v-icon color="red">mdi-minus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Leave This Team</span>
                </v-tooltip>
              </v-col>
          </v-row>
    
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="tindex < my_teams_ascoach.length - 1"
          :key="tindex+'_'+team.id" ></v-divider>
       </template> 
      </v-list>
    </v-card>

    <v-card class="mt-8">  
      <v-fab-transition>
        <v-btn
          color="avidocolor black--text"
          dark
          absolute
          top
          right
          fab
          small
          to="/client"
          v-if="upgrade_to_trainer"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-toolbar color="black">
        <v-row align="center" justify="center">
          <v-col cols="12" md="3">
            <h2 class="avidocolor--text">My Clients</h2>
            <v-divider class="white"></v-divider>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-list v-if="my_clients.length>0">
        <template v-for="(my_client,cindex) in my_clients">
        <v-list-item
          :key="cindex"      
          @click ="getuserprofile(my_client.id,my_client.name)"
        >

          <v-list-item-avatar tile size=50>
            <v-img class="rounded" alt="my_client.name" :src="(my_client.profile_pic!=null)?my_client.profile_pic:'/images/logo.jpg'" @error="my_client.profile_pic='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
              <v-list-item-title
                class="align-center"
              >{{my_client.name}}</v-list-item-title>
            </v-list-item-content>

          <v-list-item-action>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="leaveClientModel({'remove_index':cindex,'client_user_ids':my_client.id})"
                  >
                    <v-icon color="red">mdi-minus-circle</v-icon>
                  </v-btn>
                </template>
                <span>Remove This Client</span>
              </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="cindex < my_clients.length - 1"
          :key="cindex+'_'+my_client.id" ></v-divider>
        </template>
      </v-list>
    </v-card>
     
    <v-card class="mt-8">  
      <v-toolbar color="black">
        <v-row align="center" justify="center">
          <v-col cols="12" md="4">
            <h2 class="avidocolor--text">My Oraganization</h2>
            <v-divider class="white"></v-divider>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-list>
        <template v-for="(my_organization,oindex) in my_organization_ascoach">
        <v-list-item
          :key="oindex"
          @click="getorganizationprofile(my_organization.id)"
        >

          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="my_organization.name" src="/images/logo.jpg"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
              <v-list-item-title
                class="align-center"
                v-text="my_organization.name"
              ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="my_organization.is_owner==1">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="editOrganization(my_organization.id)"
                  >
                    <v-icon color="avidocolor">mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit This organization</span>
              </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="oindex < my_organization_ascoach.length - 1"
          :key="oindex+'_'+my_organization.id" ></v-divider>
        </template>
      </v-list>
    </v-card>
  </template>

  <template v-else>
    <v-alert
      type="error"
      dense
      outlined>
      Sorry, You are not part of any organization.
    </v-alert>
  </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "MyTeamTrainers",
  computed:{
    ...mapFields('myTeamTrainers',{
      get_api_call:'get_api_call',
      upgrade_to_trainer:'upgrade_to_trainer',
      my_teams_ascoach:'my_teams_ascoach',
      my_clients:'my_clients',
      my_organization_ascoach:'my_organization_ascoach',
    }),
    ...mapFields('teamProfile',{
      team_id:'team_id',
      team_name:'team_name',
    }), 
    ...mapFields('teamTrainingPreferences',{
      prefrence_team_id:'team_id',
    }), 
    ...mapFields('team',{
      edit_team_id:'team_id',
    }),
    ...mapFields('organizationProfile',{
      organizationp_id:'organization_id',
    }),
    ...mapFields('organization',{
      organization_id:'organization_id',
    }),
    ...mapFields('user',{
      coach_user_id:'user_id',
    })
  },
  methods: {
    ...mapActions('myTeamTrainers',{
      getMyTeamTrainerModel:'getMyTeamTrainerModel'      
    }), 
    ...mapActions('client',{
      leaveClientModel:'leaveClientModel'      
    }),  
    ...mapActions('team',{
      leaveTeamAsCoachModel:'leaveTeamAsCoachModel'      
    }),
    getuserprofile(client_user_id,client_user_name){
      this.$router.push({ path: 'user-profile?uid='+client_user_id+'&uname='+client_user_name });
    },
    getteamprofile(team_model){
      this.team_id=team_model.id
      this.team_name=team_model.name
      this.$router.push({ path: 'team-profile' });
    },
    getteamtrainingprefrences(team_id){
      this.prefrence_team_id=team_id
      this.$router.push({ path: 'teamtrainingpreferences' });
    },
    editteam(team_id){
      this.edit_team_id=team_id
      this.$router.push({ path: 'team' });
    },   
    createteam(){
      this.edit_team_id=0
      this.$router.push({ path: 'team' });
    },
    editOrganization(organization_id){
      this.organization_id=organization_id
      this.$router.push({ path: 'organization' });
    },
    getorganizationprofile(organization_id){
      this.organizationp_id=organization_id
      this.$router.push({ path: 'organization-profile' });
    }
  }, 
  created() {
    if(this.get_api_call){
      this.getMyTeamTrainerModel();
    }
  },
};
</script>


<template>
  <div class="workoutexerciseset">


      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <h2 class="mb-3 d-inline"><span class="float-left">A</span><span class="avidocolor--text">{{current_exer_name}}</span></h2>
          <v-icon @click="exerciseInfo()" class="ml-2" style="vertical-align: baseline;"  color="avidocolor" dark >
            mdi-information
          </v-icon>
          <v-divider class="white"></v-divider>
        </v-col>
      </v-row>
      
      <v-container class="pa-0 fill-height" fluid style="min-height: 250px">
        <v-fade-transition  mode="out-in">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-card>
                <v-img
                  :src="current_exer_largeimage"
                  class="grey darken-4"
                  @error="current_exer_largeimage='/images/logo.jpg'"
                ></v-img>

               <v-list color="black" class="pa-0">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" class="pa-0">
                      <v-list-item-action class="ma-0 align-center">
                        <div class="text-body-1 pt-2 grey--text">Rest Time</div>          
                        <div id="auto-dropdown-containter"  v-if="afterbw==undefined">
                          <vue-timepicker 
                           v-model="exerciseList[exer_index][super_index]['rest_time']" 
                            format="m:ss" 
                            :second-interval="5"
                            close-on-complete
                            auto-scroll
                            drop-direction="auto"
                            container-id="auto-dropdown-containter"
                            append-to-body
                            @change="restTimeChange"
                            v-on:change="data_change==false ? data_change=!data_change : data_change"
                          >
                          </vue-timepicker>
                        </div>
                        <div id="timer"  v-if="afterbw!=undefined" >
                            <!--  Restart Timer -->
                            <v-icon 
                              id="reset" 
                              large
                              class="mr-2"
                              v-if="resetButton"
                              color="avidocolor"
                              @click="resetTimer()">
                                mdi-restart
                            </v-icon>
                            <span class="text-h5 pt-2">
                              <span id="minutes">{{ minutes }}</span>
                              <span id="middle">:</span>
                              <span id="seconds">{{ seconds }}</span>
                            </span>
                            <!-- Start TImer -->
                              <v-icon 
                                id="start" 
                                v-if="!timer"
                                large
                                class="ml-2"
                                color="avidocolor"
                                @click="startTimer()">
                                mdi-play-circle
                              </v-icon>
                            <!--  Pause Timer -->
                              <v-icon 
                                id="stop" 
                                large
                                class="ml-2"
                                v-if="timer"
                                color="avidocolor"
                                @click="stopTimer()">
                                  mdi-pause-circle
                              </v-icon>
                        </div>
                      </v-list-item-action>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-list>

              </v-card>
            </v-col>
          </v-row>
        </v-fade-transition>

        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-if="afterbw==undefined && current_user_workout_completed==false"></th>
                    <th class="text-center">SETS</th>
                    <th class="text-center">REPS</th>
                    <th class="text-center">WEIGHT ({{current_exer_weight_type}})</th>
                    <th v-if="((afterbw==undefined && current_user_workout_completed==true) || afterbw!=undefined)  && is_workout_editable==1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in exercise_sets"  :key="item.id">

                    <td v-if="afterbw==undefined && current_user_workout_completed==false && is_workout_editable==1">
                       <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="red"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click.stop.prevent="
                                deleteSet(index)
                              "
                            >
                              <v-icon color="red">mdi-minus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete This Set</span>
                        </v-tooltip>
                    </td>

                    <td>{{ index+1 }}</td>
                    <td>
                      <v-text-field
                        v-model="exercise_sets[index].reps"
                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                        color="avidocolor"
                        placeholder="0"
                        class="setinput"
                      ></v-text-field>
                    </td>
                    <td>
<!--                       <v-edit-dialog>
                         {{ item.weight }}
                        <template v-slot:input v-if="is_workout_editable==1">
                          <v-text-field
                            v-model="exercise_sets[index].weight"
                            v-on:change="data_change==false ? data_change=!data_change : data_change"
                            label="Edit Weight"
                            single-line
                            color="avidocolor"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog> -->
                      <v-text-field
                        v-model="exercise_sets[index].weight"
                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                        color="avidocolor"
                        placeholder="0"
                        class="setinput"
                      ></v-text-field>
                    </td>
                    <td v-if="((afterbw==undefined && current_user_workout_completed==true) || afterbw!=undefined)  && is_workout_editable==1">  
                      <v-icon
                        :color="
                          (item.checkmark_status==true || item.checkmark_status=='true') ? 'avidocolor' : 'grey'
                        "
                         v-model="exercise_sets[index].checkmark_status"
                         @click="(is_workout_editable==1) ? exercise_sets[index].checkmark_status=!exercise_sets[index].checkmark_status : null" 
                         v-on:click="data_change==false ? data_change=!data_change : data_change"
                      >
                        mdi-check
                      </v-icon>
                    </td>

                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-col>
        </v-row>

      </v-container>


      <div class="clear"></div>
       <v-footer color="black" fixed padless>
          <v-row justify="center" no-gutters>

            <v-btn v-if="is_workout_editable==1" @click="exerciseSearch('SWAPEXERCISE')"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-swap-horizontal</v-icon>Swap
            </v-btn>
        
            <v-btn v-if="is_workout_editable==1" @click="addSets()"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-plus</v-icon>Add Set
            </v-btn>

            <v-btn v-if="afterbw!=undefined && (exer_index>0 || (exer_index==0 && super_index>0))" @click="nextPrevious('previous')"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-chevron-left</v-icon>Pervious
            </v-btn>

            <v-btn v-if="afterbw!=undefined && is_workout_editable==1 && (exerciseList_length==exer_index && super_exercise_length==(super_index+1))" @click="nextPrevious('finish')"  class="my-2 avidocolor--text" text>
              <v-icon>{{(cooldown!=null || finisher!=null) ? 'mdi-chevron-right' : 'mdi-content-save' }}</v-icon>{{(cooldown!=null || finisher!=null) ? 'Next' : 'Finish' }}
            </v-btn>
            
            <template v-else>
              <v-btn v-if="afterbw!=undefined"  @click="nextPrevious('next')"  class="my-2 avidocolor--text" text>
                Next<v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn v-if="afterbw==undefined" v-on:click="calendar_route=calendar_route-1"  @click="$router.go(-1)"  class="my-2 avidocolor--text" text>
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>  
            </template>

          </v-row>
        </v-footer>

  </div>
</template>

<script>

import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "WorkoutExerciseSet",
  components: {
    VueTimepicker
  },
  data () {
    return {
      dialog: false,
      afterbw:this.$route.query.bw,
      timer: null,
    //  totalTime: (25 * 60),
      resetButton: true,
    }
  },
  computed:{  
    ...mapFields('workoutCalendar',{
      calendar_route:'calendar_route',
      is_workout_editable:'is_workout_editable',
      current_user_workout_completed:'current_user_workout_completed',
    }),       
    ...mapFields('workoutExercise',{
      data_change:'data_change',
      wfcType:'wfcType',
      exer_index:'exer_index',
      super_index:'super_index',
      exerciseList:'exerciseList',
      cooldown:'cooldown',
      finisher:'finisher',
      exerciseList_length:'exerciseList_length',
      super_exercise_length:'super_exercise_length',
      current_exer_name:'current_exer_name',
      current_exercise_id:'current_exercise_id',
      current_exer_largeimage:'current_exer_largeimage',
      exercise_sets:'current_exer_sets',
      current_exer_weight_type:'current_exer_weight_type',
      current_exer_resttime:'current_exer_resttime',
      totalTime:'totalTime',
    }),
    ...mapFields('bottomSearch',{
      result_type:'result_type',
    }),
    ...mapFields('exerciseInstruction',{
      exercise_instruction_id:'exercise_instruction_id',
    }),
    minutes: function() {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function() {
      const seconds = this.totalTime - (this.minutes * 60);
      return this.padTime(seconds);
    }
  },
  methods: {
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
    }),
    startTimer: function() {
      this.timer = setInterval(() => this.countdown(), 1000);
    },
    stopTimer: function() {
      clearInterval(this.timer);
      this.timer = null;
    },
    resetTimer: function() {
      this.totalTime = this.current_exer_resttime //(25 * 60);
      clearInterval(this.timer);
      this.timer = null;
    },
    padTime: function(time) {
      return (time < 10 ? '0' : '') + time;
    },
    countdown: function() {
      if(this.totalTime >= 1){
        this.totalTime--;
      } else{
        this.totalTime = 0;
        this.resetTimer()
      }
    },
    exerciseInfo(){
      this.exercise_instruction_id=this.current_exercise_id
      this.$router.push({ path: 'exercise-instruction' });
    },
    nextPrevious(data){

      if(data=='finish'){
        let routeName='workoutwfc'
        this.calendar_route=this.calendar_route+1
        if(this.finisher!=null){
          this.wfcType='FINISHER'
          this.$router.push({ path: routeName+'?bw=1' });
        }else if(this.cooldown!=null){
          this.calendar_route=this.calendar_route-1
          this.wfcType='COOLDOWN'
        }else if(this.afterbw){
          if(this.finisher==null && this.cooldown==null){
            this.wfcType='finishworkout'            
            this.$router.push({ path: 'workoutexercise?fw=1' });
          }else{
            this.$router.push({ path: routeName+'?bw=1' });
          }
        }else{
          this.$router.push({ path: routeName });
        }   
      }else{
        
        if(data=='next'){
          this.super_index=this.super_index+1;
          if(this.super_index >= this.super_exercise_length){
            this.super_index=0;
            this.exer_index=this.exer_index+1;
          }else if(this.exer_index < this.exerciseList_length && this.super_index == this.super_exercise_length){
            this.exer_index=this.exer_index+1;
          }
        }

        if(data=='previous'){
          if(this.super_index >= this.super_exercise_length){
            this.exer_index=this.exer_index-1;
            this.super_index==this.exerciseList[this.exer_index].length
          }else if(this.super_index < this.super_exercise_length && this.super_index>0){
            this.super_index=this.super_index-1;
          }else if(this.super_index==0){
            this.exer_index=this.exer_index-1;
          }     
        }
            
        if(this.afterbw!=undefined){
          let crestTime=this.exerciseList[this.exer_index][this.super_index]['rest_time']
          if(typeof crestTime=='string'){
            let restvalue=crestTime.split(":");
            let restTime=parseInt(restvalue[0]*60)+parseInt(restvalue[1]);
            this.exerciseList[this.exer_index][this.super_index]['rest_time']=restTime
          }
        }
        this.current_exer_resttime=this.exerciseList[this.exer_index][this.super_index]['rest_time']
        this.totalTime=this.exerciseList[this.exer_index][this.super_index]['rest_time']
        this.current_exercise_id=this.exerciseList[this.exer_index][this.super_index]['exercise_id']
        this.current_exer_name=this.exerciseList[this.exer_index][this.super_index]['exercise_name']
        this.current_exer_largeimage=this.exerciseList[this.exer_index][this.super_index]['exercise_large_image']
        this.exercise_sets=this.exerciseList[this.exer_index][this.super_index]['sets']
        this.super_exercise_length=this.exerciseList[this.exer_index].length

      }
    },  
    exerciseSearch(result_type){
      this.result_type=result_type
      this.getAvidoExercise(result_type)
    },
    addSets(){
      this.data_change=true
      let current_set_count=this.exercise_sets.length
      let new_set={
        checkmark_status : false,
        reps : null,
        set_id : this.current_exercise_id+'_'+current_set_count,
        weight : null,
      }
      this.exerciseList[this.exer_index][this.super_index]['exercise_set_count']=current_set_count+1;
      this.exercise_sets.push(new_set)
    },
    deleteSet(remove_index){
        this.data_change=true
        this.exercise_sets.splice(remove_index, 1);  
        let current_set_count=this.exercise_sets.length  
        this.exerciseList[this.exer_index][this.super_index]['exercise_set_count']=current_set_count;  
    },
    restTimeChange(eventData){
      this.exerciseList[this.exer_index][this.super_index]['rest_time']=eventData.displayTime
    }
  },
  created() {
    this.exerciseList_length=this.exerciseList.length-1;
    if(this.afterbw!=undefined){
      let crestTime=this.exerciseList[this.exer_index][this.super_index]['rest_time']
      if(typeof crestTime=='string'){
        let restvalue=crestTime.split(":");
        let restTime=parseInt(restvalue[0]*60)+parseInt(restvalue[1]);
        this.exerciseList[this.exer_index][this.super_index]['rest_time']=restTime
        this.current_exer_resttime=restTime
        this.totalTime=restTime
      }
    }
  },
  
  beforeDestroy(){
    let component_name=this.$options.name
    let redirect_location=document.location.pathname

    if(this.afterbw==undefined && component_name=='WorkoutExerciseSet' && redirect_location=='/workoutexercise'){
      this.calendar_route=this.calendar_route-1
    }
  },
};
</script>

<style scoped>
.v-text-field>.v-input__control>.v-input__slot:before{
  border-style: none !important;
}
</style>

<style>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":""}},[_c('v-form',{ref:"searchlibrarycycle",attrs:{"lazy-validation":"","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-autocomplete',{staticClass:"pa-md-4",staticStyle:{"padding-bottom":"0!important"},attrs:{"name":"assign_library_cycle_id","items":_vm.user_LCycles_list,"label":"Start typing to Search Library Cycle","prepend-icon":"mdi-database-search","color":"avidocolor","item-text":"name","item-value":"id","filled":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',{attrs:{"tile":"","size":"50"}},[_c('v-img',{staticClass:"rounded",attrs:{"src":data.item.image != null
                ? data.item.image
                : '/images/logo.jpg'},on:{"error":function($event){data.item.image = '/images/logo.jpg'}}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]}}]),model:{value:(_vm.assign_library_cycle_id),callback:function ($$v) {_vm.assign_library_cycle_id=$$v},expression:"assign_library_cycle_id"}}),_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pa-md-4",attrs:{"color":"avidocolor","label":"Select Cycle Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.assign_cycle_start_date),callback:function ($$v) {_vm.assign_cycle_start_date=$$v},expression:"assign_cycle_start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('v-date-picker',{attrs:{"color":"avidocolor black--text","min":_vm.today,"scrollable":""},model:{value:(_vm.assign_cycle_start_date),callback:function ($$v) {_vm.assign_cycle_start_date=$$v},expression:"assign_cycle_start_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"avidocolor"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"avidocolor"},on:{"click":function($event){return _vm.$refs.dialog1.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pa-md-4",attrs:{"color":"avidocolor","label":"Select Cycle End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.assign_cycle_end_date),callback:function ($$v) {_vm.assign_cycle_end_date=$$v},expression:"assign_cycle_end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"color":"avidocolor black--text","min":_vm.today,"scrollable":""},model:{value:(_vm.assign_cycle_end_date),callback:function ($$v) {_vm.assign_cycle_end_date=$$v},expression:"assign_cycle_end_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"avidocolor"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"avidocolor"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"submit","large":"","block":"","color":"avidocolor"}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v("save ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
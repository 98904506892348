<template>
  <div class="register">

    <v-card class="pa-8">
      <v-form
        ref="registerform"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
        v-model="validate"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="firstname"
              :rules="firstnameRules"
              required
              class="pa-md-4"
              prepend-icon="mdi-account"
              name="firstname"
              label="First Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastname"
              :rules="lastnameRules"
              required
              class="pa-md-4"
              prepend-icon="mdi-account"
              name="lastname"
              label="Last Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          required
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          class="pa-md-4"
          prepend-icon="mdi-lock"
          name="password"
          label="Password"
          :type="show ?'text': 'password'"
          :append-icon="show ?'mdi-eye':'mdi-eye-off'" 
          @click:append="show=!show"
          color="avidocolor"
        ></v-text-field>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor">
            Register
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-row align="center" justify="center">
      <v-col>
        <v-btn text color="avidocolor" to="/login"> LogIn </v-btn> |
        <v-btn text color="avidocolor"> Forget Password ? </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "Register",
  data: () => ({
    show:false,
    validate: false,
    userExists: false,
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    firstnameRules: [(v) => !!v || "First Name is required"],
    lastnameRules: [(v) => !!v || "Last Name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 20) || "Password must be less than 20 characters",
    ],
  }),
  methods: {
    onSubmit: function () {
      if (this.$refs.registerform.validate()) {
        let email = this.email;
        let password = this.password;
        let first_name = this.firstname;
        let last_name = this.lastname;
        this.$store
          .dispatch("user/register", { first_name, last_name, email, password })
          .then(() => this.$router.push("/trainingpreferences"))
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              this.$store.commit("SET_NOTIFICATION", {
                display: true,
                text: error_mesg,
                alertClass: "error",
              });
            }
          });
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
};
</script>
<template>
  <div class="accountsettings">

    <v-card class="pa-8">
      <v-form
        ref="accountsettings"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="userModel.first_name"
              required
              class="pa-md-4"
              prepend-icon="mdi-account"
              name="firstname"
              label="First Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="userModel.last_name"
              required
              class="pa-md-4"
              prepend-icon="mdi-account"
              name="lastname"
              label="Last Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="userModel.email"
          required
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="userModel.birth_date"
              label="Birthday date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="pa-md-4"
              color="avidocolor"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="userModel.birth_date"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="userModel.height_feet"
              required
              class="pa-md-4"
              prepend-icon="mdi-human-handsdown"
              name="height_feet"
              label="Height Feet"
              color="avidocolor"
              suffix="FEET"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="userModel.height_inch"
              required
              class="py-4"
              name="height_inch"
              label="Height Inch"
              color="avidocolor"
              suffix="INCH"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="userModel.weight"
          required
          class="pa-md-4"
          prepend-icon="mdi-weight-kilogram"
          name="weight"
          label="Weight"
          color="avidocolor"
          suffix="LBS"
        ></v-text-field>

        <v-row class="pl-md-4">
          <v-col align="left">
              <v-icon>mdi-human-male-female</v-icon> Gender :
              <v-btn-toggle
                v-model="userModel.gender"
                tile
                color="avidocolor"
                group
                mandatory
              >
                <v-btn value="F">
                  F
                </v-btn>

                <v-btn value="M">
                  M
                </v-btn>

                <v-btn value="X">
                  X
                </v-btn>
              </v-btn-toggle>
          </v-col>
        </v-row>  
        
        <v-text-field
          v-if="userModel.upgrade_to_trainer==true"
          v-model="userModel.client_join_code"
          required
          class="pa-md-4"
          prepend-icon="mdi-barcode"
          name="client_join_code"
          label="Client JoinCode"
          color="avidocolor"
        ></v-text-field>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Accountsettings",
  data: () => ({
    valid: true,
    menu: false,
  }),
  computed:{
    ...mapFields('accountSettings',{
      userModel:'userModel',
    })
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions('accountSettings',{
      getModel:'getModel',
      updateModel:'updateModel',
    }),
    save(date) {
      this.$refs.menu.save(date);
    },
    onSubmit: function () {
      if (this.$refs.accountsettings.validate()) {
        let userModel = this.userModel;
        this.updateModel(userModel);
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.getModel();
  },
};
</script>
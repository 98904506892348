<template>
  <div class="editworkoutexercise">

        <v-card class="mx-auto" width="100%">
          <v-list color="black" class="pa-0">
            <v-list-item>
              <v-list-item-avatar tile size=50>
                <v-img class="rounded" alt="avtar_image" src="/images/logo.jpg"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left align-self-start">
                <v-list-item-title>
                  <span class="font-weight-bold">Description:</span>
                  <span class="text-wrap">
                   {{current_workout_description}}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-row>
              <v-col cols="4" class="pa-2">Sets:{{total_sets}}</v-col>
              <v-col cols="4" class="pa-2">Reps:{{total_reps}}</v-col>
              <v-col cols="4" class="pa-2">Lbs:{{total_weight}}</v-col>
            </v-row>
            <v-divider></v-divider>
          </v-list>


        <draggable v-model="exerciseList"
          handle=".handle"
          ghost-class="grey"
          @start="dragging = true"
          @end="dragging = false"
          v-if="
            (exerciseList.length>0)
          " 
          color="grey darken-4"
          @change="data_change==false ? data_change=!data_change : data_change"
          >
            <transition-group>
                  <div
                    class="list-group-item"
                    v-for="(exerciseListmodel,eindex) in exerciseList"
                    :key="eindex"
                  >

                  <template  v-if = "exerciseListmodel.length==1">
                    <template v-for="(model,mindex) in exerciseListmodel">
                      <v-list-item class="handle" :key="mindex+model.user_workout_exercise_id+model.exercise_id" >
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="red"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="avidocolor">mdi-drag</v-icon>
                              </v-btn>
                            </template>
                            <span>ReOrder This Exercises</span>
                          </v-tooltip>
                          
                        <v-list-item-avatar tile size=50>
                          <v-img class="rounded" :alt="model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content class="pl-2 text-left">
                          <v-list-item-title
                            class="align-center"
                            v-text="model.exercise_name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="align-center ma-0">
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  color="red"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop.prevent="
                                    removeExercise(eindex,model)
                                  "
                                >
                                  <v-icon color="red">mdi-minus-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove This Exercise</span>
                            </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </template>

                  <template  v-if = "exerciseListmodel.length>1">
                    <template v-for="(supermodel,superindex) in exerciseListmodel">
                        
                        <v-list-item class="handle"  :key="superindex+supermodel.user_workout_exercise_id+supermodel.exercise_id">
                          <v-tooltip left  v-if="superindex==0" >
                            <template  v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="avidocolor">mdi-drag</v-icon>
                              </v-btn>
                            </template>
                            <span>ReOrder This Exercises</span>
                          </v-tooltip>

                          <div class="pl-9" v-if="superindex>0" ></div>
                          <v-list-item-avatar tile size=50>
                             <v-img class="rounded" alt="avtar_image" :src="(supermodel.exercise_image_thumbnail!=null)?supermodel.exercise_image_thumbnail:'/images/logo.jpg'" @error="supermodel.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="pl-2 text-left">
                            <v-list-item-title class="align-center"
                              >{{supermodel.exercise_name}}</v-list-item-title
                            >
                          </v-list-item-content>

                          <v-list-item-action class="align-center ma-0"  v-if="superindex==0" >
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  color="red"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop.prevent="
                                    removeExercise(eindex,supermodel)
                                  "
                                >
                                  <v-icon color="red">mdi-minus-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove This Exercise</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item  v-if="superindex < exerciseListmodel.length - 1" :key="'icon'+superindex+supermodel.user_workout_exercise_id+supermodel.exercise_id" style="min-height: 0px !important;">
                          <v-list-item-avatar height="0px"></v-list-item-avatar>
                          <v-list-item-content class="d-block ma-n5 pa-0 text-left">
                            <v-list-item-avatar class="ml-12 mt-0 mb-0 align-center">
                              <v-img @click="removeSuperSet(eindex,superindex)"  alt="superst" :src="imageSource"></v-img>
                            </v-list-item-avatar>
                          </v-list-item-content>
                        </v-list-item>

                    </template>
                  </template>
                 
                  <v-list-item  v-if="eindex < exerciseList.length - 1" style="min-height: 0px !important;">
                    <v-list-item-avatar height="0px"></v-list-item-avatar>
                    <v-list-item-content class="d-block ma-n5 pa-0 text-left">
                      <v-list-item-avatar class="ml-12 mt-0 mb-0 align-center">
                        <v-img @click="addSuperSet(eindex)"  alt="superst" :src="grayimageSource"></v-img>
                      </v-list-item-avatar>
                    </v-list-item-content>
                  </v-list-item>


                  <v-divider v-if="eindex < exerciseList.length - 1"
                      :key="eindex" ></v-divider>

                  </div>
            </transition-group>
        </draggable>
        </v-card>

        <div class="clear"></div>
        <v-footer color="black" fixed padless>
          <v-row justify="center" no-gutters>
              <v-btn  @click="getAvidoExercise('EXERICSE')" class="my-2 avidocolor--text" text>
                <v-icon>mdi-plus-box</v-icon>Add Exercise
              </v-btn>

              <v-btn @click="$router.go(-1)" class="my-2 avidocolor--text" text>
                <v-icon>mdi-content-save</v-icon>save
              </v-btn>
          </v-row>
        </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import draggable from 'vuedraggable';

export default {
  name: "EditWorkoutExercise",
  data: () => ({
    imageSource: "/images/superset_icon.png",
    grayimageSource: "/images/gray_superset_icon.png"
  }),
  components: {
    draggable
  },
  computed:{
    ...mapFields('workoutCalendar',{
      is_workout_editable: "is_workout_editable",
      current_workout_description: "current_workout_description",
    }),

    ...mapFields('workoutExercise',[
      'total_reps', 
      'total_sets', 
      'total_weight', 
      'exerciseList',
      'data_change',
    ]),
  },
  methods: {
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
    }),
    removeExercise(index,exe_model) { 
      this.data_change=true
      if("user_workout_exercise_id" in exe_model){
        if (index >= 0) this.exerciseList.splice(index, 1);
      }
    },  

    removeSuperSet(eindex,superindex) { 
      this.data_change=true
      let newexerciseList=[]
      if(this.exerciseList!=null){
        for (const [key, nexerciseList]  of Object.entries(this.exerciseList)) {
          if(key==eindex){
            let firsthalfexercise=[]
            let secondhalfexercise=[]
            for (const [superkey, superexeriseList]  of Object.entries(this.exerciseList[eindex])) {
              if(superkey<=superindex){
                firsthalfexercise.push(superexeriseList);
              }else{
                secondhalfexercise.push(superexeriseList);
              }
            }
            newexerciseList.push(firsthalfexercise);
            newexerciseList.push(secondhalfexercise);
          }else{
            newexerciseList.push(nexerciseList);
          }
        }
        this.exerciseList=newexerciseList
      }
    }, 
    
    addSuperSet(eindex) {
      this.data_change=true 
      if(this.exerciseList[eindex]!=null){
        let superexercise=[];
        let secondindex=eindex+1;
        for (let firstsuperexercises  of Object.values(this.exerciseList[eindex])) {           
          superexercise.push(firstsuperexercises);
        }   
        for (let secondsuperexercises  of Object.values(this.exerciseList[secondindex])) {           
          superexercise.push(secondsuperexercises);
        }
        if (eindex >= 0) this.exerciseList.splice(eindex, 1);
        this.exerciseList[eindex]=superexercise;        
      }
    },
  },
};
</script>

<style scoped>
.handle {
  cursor: move; 
}
</style>
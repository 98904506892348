var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MyTeamTrainers"},[(_vm.my_organization_ascoach.length>0)?[_c('v-card',{staticClass:"mt-8"},[_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"avidocolor black--text","dark":"","absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.createteam()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-toolbar',{attrs:{"color":"black"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h2',{staticClass:"avidocolor--text"},[_vm._v("My Teams")]),_c('v-divider',{staticClass:"white"})],1)],1)],1),(_vm.my_teams_ascoach.length>0)?_c('v-list',{attrs:{"two-line":""}},[_vm._l((_vm.my_teams_ascoach),function(team,tindex){return [_c('v-list-item',{key:tindex,on:{"click":function($event){return _vm.getteamprofile(team)}}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"50"}},[_c('v-img',{staticClass:"rounded",attrs:{"alt":"team.name","src":(team.profile_pic!=null)?team.profile_pic:'/images/logo.jpg'},on:{"error":function($event){team.profile_pic='/images/logo.jpg'}}})],1),_c('v-list-item-content',{staticClass:"text-left align-self-start"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(team.name)}}),_c('v-list-item-subtitle',[_c('span',{staticClass:"subtitle-1 font-weight-light avidocolor--text",on:{"click":function($event){return _vm.getteamtrainingprefrences(team.id)}}},[_vm._v(" Training Preference ")])])],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editteam(team.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"avidocolor"}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit This Team")])])],1),_c('v-col',{staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.leaveTeamAsCoachModel({'remove_index':tindex,'team_id':team.id,'delete_coach_user_ids':_vm.coach_user_id})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Leave This Team")])])],1)],1)],1)],1),(tindex < _vm.my_teams_ascoach.length - 1)?_c('v-divider',{key:tindex+'_'+team.id}):_vm._e()]})],2):_vm._e()],1),_c('v-card',{staticClass:"mt-8"},[_c('v-fab-transition',[(_vm.upgrade_to_trainer)?_c('v-btn',{attrs:{"color":"avidocolor black--text","dark":"","absolute":"","top":"","right":"","fab":"","small":"","to":"/client"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-toolbar',{attrs:{"color":"black"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h2',{staticClass:"avidocolor--text"},[_vm._v("My Clients")]),_c('v-divider',{staticClass:"white"})],1)],1)],1),(_vm.my_clients.length>0)?_c('v-list',[_vm._l((_vm.my_clients),function(my_client,cindex){return [_c('v-list-item',{key:cindex,on:{"click":function($event){return _vm.getuserprofile(my_client.id,my_client.name)}}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"50"}},[_c('v-img',{staticClass:"rounded",attrs:{"alt":"my_client.name","src":(my_client.profile_pic!=null)?my_client.profile_pic:'/images/logo.jpg'},on:{"error":function($event){my_client.profile_pic='/images/logo.jpg'}}})],1),_c('v-list-item-content',{staticClass:"text-left"},[_c('v-list-item-title',{staticClass:"align-center"},[_vm._v(_vm._s(my_client.name))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","dark":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.leaveClientModel({'remove_index':cindex,'client_user_ids':my_client.id})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove This Client")])])],1)],1),(cindex < _vm.my_clients.length - 1)?_c('v-divider',{key:cindex+'_'+my_client.id}):_vm._e()]})],2):_vm._e()],1),_c('v-card',{staticClass:"mt-8"},[_c('v-toolbar',{attrs:{"color":"black"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h2',{staticClass:"avidocolor--text"},[_vm._v("My Oraganization")]),_c('v-divider',{staticClass:"white"})],1)],1)],1),_c('v-list',[_vm._l((_vm.my_organization_ascoach),function(my_organization,oindex){return [_c('v-list-item',{key:oindex,on:{"click":function($event){return _vm.getorganizationprofile(my_organization.id)}}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"50"}},[_c('v-img',{staticClass:"rounded",attrs:{"alt":my_organization.name,"src":"/images/logo.jpg"}})],1),_c('v-list-item-content',{staticClass:"text-left"},[_c('v-list-item-title',{staticClass:"align-center",domProps:{"textContent":_vm._s(my_organization.name)}})],1),(my_organization.is_owner==1)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","dark":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editOrganization(my_organization.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"avidocolor"}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit This organization")])])],1):_vm._e()],1),(oindex < _vm.my_organization_ascoach.length - 1)?_c('v-divider',{key:oindex+'_'+my_organization.id}):_vm._e()]})],2)],1)]:[_c('v-alert',{attrs:{"type":"error","dense":"","outlined":""}},[_vm._v(" Sorry, You are not part of any organization. ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'
import { isAfter,addSeconds,subMinutes,differenceInMilliseconds } from 'date-fns'
import {refreshToken} from "../../session-manager";
import { getField,updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  name: 'user',
  state: {
    status: '',
    Minutestosubstract: 2,
    upgrade_to_trainer: 0,
    owner_of_organization: 0,
    user_id: localStorage.getItem('user_id'),
    username: '',
    user_profile_pic: (typeof(localStorage.getItem("user_profile_pic")) != "undefined" && localStorage.getItem("user_profile_pic") !== null) ? localStorage.getItem("user_profile_pic") : null,
    access_token: localStorage.getItem("access_token") || null,
    refresh_token: localStorage.getItem("refresh_token") || null,
    tokensExpiry: localStorage.getItem("tokensExpiry") || null,
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = null
      state.username = null
      state.access_token = null
      state.refresh_token = null
      state.tokensExpiry = null
      state.user_profile_pic = null
      state.upgrade_to_trainer = 0
      state.owner_of_organization = 0
      state.user_id = 0
    },
    set_user(state,resp_data){
      const first_name = resp_data.first_name
      const last_name = resp_data.last_name                            
      const username = first_name+' '+last_name
      state.username = username
      state.user_id = resp_data.user_id
      state.upgrade_to_trainer = resp_data.upgrade_to_trainer
      state.owner_of_organization = resp_data.owner_of_organization
      localStorage.setItem("user_id", resp_data.user_id);
      localStorage.setItem("user_name", username);
      localStorage.setItem("dark_text_color", resp_data.current_displayskin.dark_text_color);
      // store._vm.$session.set('username',username);

      if(resp_data.profile_pic!=null){
        const user_profilepic = resp_data.profile_pic
        state.user_profile_pic = user_profilepic
        localStorage.setItem("user_profile_pic", user_profilepic);
      }

    },
    update_auth_tokens(state,tokenData) {
        localStorage.setItem("access_token", tokenData.access_token); //sometimes tokens are in snake case and other times they are in camelcase :/
        state.access_token = localStorage.getItem("access_token");

      //  console.log('update refresh_token-'+tokenData.refresh_token)
        localStorage.setItem("refresh_token", tokenData.refresh_token);
        state.refresh_token = localStorage.getItem("refresh_token");

        const tokensExpiry = addSeconds(new Date(), tokenData.expires_in);
      //  const tokensExpiry = tokenData.expires_in;
        state.tokensExpiry = tokensExpiry;
        localStorage.setItem("tokensExpiry", tokensExpiry);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' +tokenData.access_token
    },
    updateField
  },
  actions: {
    login({dispatch,commit}, user){
        return new Promise((resolve, reject) => {
          commit('auth_request')
          axios({url: 'login', data: user, method: 'POST' })
          .then(resp => {
            const resp_code = resp.status
            if(resp_code==200){

              let tokenData=resp.data.response.token
              commit("update_auth_tokens", tokenData);
              // const user = resp.data.response.current_displayskin
              const resp_data = resp.data.response
              commit('set_user', resp_data)

              const tokenExpiryDate=  new Date(this.getters["user/tokensExpiry"]);
              const MinutesBeforeExpiry = subMinutes(tokenExpiryDate, this.getters["user/Minutestosubstract"]);
              const now = new Date();
              const next_timeout=differenceInMilliseconds(MinutesBeforeExpiry, now)

              setTimeout(refreshToken,next_timeout);
              resolve(resp)
            }else{
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass :"error"
              },{ root:true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              commit('auth_error', error)
              commit("update_auth_tokens", {});
              reject(error)
            }
          })
        })
    },
    register({commit,dispatch}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: 'register', data: user, method: 'POST' })
        .then(resp => {
          const resp_code = resp.status
          if(resp_code==200){

            const resp_data = resp.data.response
            let tokenData=resp_data.token
            commit("update_auth_tokens", tokenData);            
            commit('set_user', resp_data); 

            const tokenExpiryDate=  new Date(this.getters["user/tokensExpiry"]);
            const MinutesBeforeExpiry = subMinutes(tokenExpiryDate, this.getters["user/Minutestosubstract"]);
            const now = new Date();
            const next_timeout=differenceInMilliseconds(MinutesBeforeExpiry, now)

            setTimeout(refreshToken,next_timeout);
            resolve(resp) 
          }else{
            dispatch("setNotification", {
              display: true,
              text: this.app_error_mesg,
              alertClass :"error"
            },{ root:true });
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            commit('auth_error', error)
            commit("update_auth_tokens", {});
            reject(error)
          }
        })
      })
    },
  },
  getters: {
      isLoggedIn: state => !!state.access_token,
      user_name: state => (state.username!=='')?state.username : localStorage.getItem("user_name"),
      tokensExpiry: state => state.tokensExpiry,
      Minutestosubstract: state => state.Minutestosubstract,
      isTokenActive(state) {
        const now = new Date();
        const tokenExpiryDate=  new Date(state.tokensExpiry);
        let MinutesBeforeExpiry = subMinutes(tokenExpiryDate, state.Minutestosubstract);
        if (!state.tokensExpiry) {
          return false;
        }else if (state.tokensExpiry=='Invalid Date' || MinutesBeforeExpiry=='Invalid Date'){
          return false;
        }else if (isAfter(now, MinutesBeforeExpiry)) {
          return false;
        }else{
          return true;
        }
      },
      getField,
  }
};

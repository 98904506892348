<template>
  <div class="trainingpreferences">
    <v-card flat class="pa-8">
      <v-form
        ref="trainingpreferencesform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-select
          v-model="current_calendar"
          :items="current_calendar_option"
          item-color="avidocolor"
          label="Calendar Type"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-format-list-bulleted-type"
          :rules="[(v) => !!v || 'Calendar Type is required']"
        ></v-select> 
        

        <v-select
          v-if="current_calendar=='TEAM'"
          v-model="current_team_id"
          :items="athlete_team"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Select Team"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-human"
          :rules="(current_calendar=='TEAM') ? [(v) => !!v || 'Team is required'] : []"
        ></v-select>

        <v-select
          v-if="current_calendar=='LIBRARY'"
          v-model="current_library_program_id"
          :items="library_program"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Select Library Program"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-human"
          :rules="(current_calendar=='LIBRARY') ? [(v) => !!v || 'Library Program is required'] : []"
        ></v-select>

        <v-select
          v-if="current_calendar!='TEAM'"
          :disabled="(current_calendar=='AVIDO') ? false : true"
          v-model="program_type"
          :items="program_type_option"
          item-color="avidocolor"
          label="Program Type"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-format-list-bulleted-type"
          :rules="(current_calendar=='AVIDO') ? [(v) => !!v || 'Program Type is required'] : []"
           @change="onChangeProgramType()" 
        ></v-select>

        <v-select
         :disabled="(current_calendar=='AVIDO' && program_type=='GOAL') ? false : true"
          v-model="goal_sport_id"
          :items="goal_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Select Goal"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-passport"
          :rules="(current_calendar=='AVIDO' && program_type=='GOAL') ? [(v) => !!v || 'Goal is required'] : []"
        ></v-select>

        <v-select
         :disabled="(current_calendar=='AVIDO' && program_type=='SPORT') ? false : true"
          v-model="goal_sport_id"
          :items="sport_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Select Sport"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-passport"
          :rules="(current_calendar=='AVIDO' && program_type=='SPORT') ? [(v) => !!v || 'Sport is required'] : []"
        >
        </v-select>

        <v-select
         :disabled="((current_calendar=='AVIDO' && program_type=='SPORT') || current_calendar=='TEAM')  ? false : true"
          v-model="position_id"
          :items="position_option[goal_sport_id]"
          item-text="name"
          item-value="position_filter_id"
          item-color="avidocolor"
          label="Position"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-human"
          :rules="(current_calendar=='AVIDO' && program_type=='SPORT') ? [(v) => !!v || 'Position is required'] : []"
        ></v-select>

        <v-select
          :disabled="(current_calendar=='LIBRARY') ? true : false"
          v-model="target1_muscle_id"
          :items="muscles_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Target Muscles1"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-target"
        ></v-select>

        <v-select
          :disabled="(current_calendar=='LIBRARY') ? true : false"
          v-model="target2_muscle_id"
          :items="muscles_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Target Muscles2"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-target"
        ></v-select>

        <v-select
         :disabled="(current_calendar=='AVIDO') ? false : true"
          v-model="no_workouts_per_week"
          :items="no_workouts_per_week_option"
          item-color="avidocolor"
          label="Workout Per Week"
          color="avidocolor"
          class="pa-md-4 pb-md-0"
          prepend-icon="mdi-calendar-clock"
          :rules="(current_calendar=='AVIDO') ? [(v) => !!v || 'Workout Per Week  is required'] : []"
        ></v-select>

        <v-row class="pl-md-4 text-left">
          <v-col cols="12">
            <v-input
              :disabled="(current_calendar=='AVIDO') ? false : true"
              prepend-icon="mdi-wrench"
            >
              Available Equipments
            </v-input>
          </v-col>
        </v-row>
        <v-row class="pl-md-12">
          <v-col
            cols="6"
            sm="2"
            md="2"
            v-for="equp_item in equipment_option"
            :key="equp_item.id"
          >
            <v-checkbox
              :disabled="(current_calendar=='AVIDO') ? false : true"
              v-model="equipment_ids"
              :label="equp_item.name"
              :value="equp_item.id"
              :checked="true"
              color="avidocolor"
              hide-details
              class="mt-0"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-dialog
          ref="programstart_dialog"
          v-model="programstart_modal"
          :return-value.sync="program_start_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="(current_calendar=='AVIDO') ? false : true"
              v-model="program_start_date"
              label="Program Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              color="avidocolor"
              class="pa-md-4 pt-md-8"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="program_start_date"
            scrollable
            color="avidocolor black--text"
            :min="today"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="programstart_modal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="avidocolor"
              @click="
                $refs.programstart_dialog.save(
                  program_start_date
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <template  v-if="program_type=='SPORT'">
          <v-dialog
            ref="sport_inseason_start_dialog"
            v-model="sport_inseason_start_modal"
            :return-value.sync="sport_inseason_start"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="(current_calendar=='AVIDO') ? false : true"
                v-model="sport_inseason_start"
                label="Inseason Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                color="avidocolor"
                class="pa-md-4"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sport_inseason_start"
              scrollable
              color="avidocolor black--text"
              :min="today"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="error"
                @click="sport_inseason_start_modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="avidocolor"
                @click="
                  $refs.sport_inseason_start_dialog.save(
                    sport_inseason_start
                  )
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="sport_inseason_end_dialog"
            v-model="sport_inseason_end_modal"
            :return-value.sync="sport_inseason_end"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="(current_calendar=='AVIDO') ? false : true"
                v-model="sport_inseason_end"
                label="Inseason End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                color="avidocolor"
                class="pa-md-4"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sport_inseason_end"
              scrollable
              color="avidocolor black--text"
              :min="today"
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="sport_inseason_end_modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="avidocolor"
                @click="
                  $refs.sport_inseason_end_dialog.save(
                    sport_inseason_end
                  )
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>

        <v-dialog
          v-if="program_type=='GOAL'"
          ref="goal_end_date_dialog"
          v-model="goal_end_date_modal"
          :return-value.sync="goal_end_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="(current_calendar=='AVIDO') ? false : true"
              v-model="goal_end_date"
              label="Goal End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              color="avidocolor"
              class="pa-md-4"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="goal_end_date"
            scrollable
            color="avidocolor black--text"
            :min="today"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="goal_end_date_modal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="avidocolor"
              @click="
                $refs.goal_end_date_dialog.save(goal_end_date)
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor"> <v-icon>mdi-content-save</v-icon>save  </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";


export default {
  name: "Trainingpreferences",
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    valid: false,
    programstart_modal: false,
    sport_inseason_start_modal: false,
    sport_inseason_end_modal: false,
    goal_end_date_modal: false,
  }),
  computed:{
    ...mapFields('trainingPreferences',{
      current_calendar:'userTrainingState.current_calendar',
      program_type:'userTrainingState.program_type',
      program_start_date:'userTrainingState.program_start_date',
      sport_inseason_start:'userTrainingState.sport_inseason_start',
      sport_inseason_end:'userTrainingState.sport_inseason_end',
      goal_end_date:'userTrainingState.goal_end_date',
      no_workouts_per_week:'userTrainingState.no_workouts_per_week',
      goal_sport_id:'userTrainingState.goal_sport_id',
      position_id:'userTrainingState.position_id',
      equipment_ids:'userTrainingState.equipment_ids',
      target1_muscle_id:'userTrainingState.target1_muscle_id',
      target2_muscle_id:'userTrainingState.target2_muscle_id',
      current_team_id:'userTrainingState.current_team_id',
      current_library_program_id:'userTrainingState.current_library_program_id',
      current_calendar_option:'current_calendar_option',
      no_workouts_per_week_option:'no_workouts_per_week_option',
      goal_option:'goal_option',
      sport_option:'sport_option',
      position_option:'position_option',
      muscles_option:'muscles_option',
      equipment_option:'equipment_option',
      program_type_option:'program_type_option',
      athlete_team:'athlete_team',
      library_program:'library_program',
    })
  },
  methods: {
    ...mapActions('trainingPreferences',{
      getTrainingModel:'getTrainingModel',
      updateTrainingModel:'updateTrainingModel',
    }),
    onChangeProgramType: function () {
      this.goal_sport_id=0
      this.position_id=0
    },
    onSubmit: function () {
      if (this.$refs.trainingpreferencesform.validate()) {
        this.updateTrainingModel();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.getTrainingModel();
  },
};
</script>
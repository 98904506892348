import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    exer_index: 0,
    super_index: 0,
    data_change: false,
    total_reps: 0,
    total_sets: 0,
    total_weight: 0,
    exerciseList: [],
    exerciseList_length: 0,
    super_exercise_length: 0,
    totalTime: 0,
    current_exer_resttime: 0,
    current_exercise_id: 0,
    current_exer_name: null,
    current_exer_largeimage: null,
    current_exer_sets: [],
    current_exer_weight_type: null,
    wfcType: null,
    warmup: null,
    cooldown: null,
    finisher: null,
    is_team_wfc: 0,
    wfc_type: null,
    selectedWFC: null,
    wfc_search_array: null,
  },
  mutations: {
    unsetWFC(state) {
      state.wfc_search_array=null
    }, 
    setWFC(state, resp_data) {
      state.wfc_search_array=resp_data
    },
    unsetModel(state) {
      state.exer_index = 0
      state.super_index = 0
      state.data_change = false
      state.total_reps = 0
      state.total_sets = 0
      state.total_weight = 0
      state.exerciseList = []
      state.exerciseList_length = 0
      state.super_exercise_length = 0
      state.totalTime = 0
      state.current_exer_resttime = 0
      state.current_exercise_id = 0
      state.current_exer_name = null
      state.current_exer_largeimage = null
      state.current_exer_weight_type = null
      state.current_exer_sets = []
      state.wfcType = null
      state.warmup = null
      state.cooldown = null
      state.finisher = null
      state.is_team_wfc = 0
      state.wfc_type = null
      state.selectedWFC = null
      state.wfc_search_array = null
    },

    setModel(state, resp_data) {

      this.state.workoutCalendar.current_user_workout_id = resp_data.user_workout_id
      // this.state.workoutCalendar.current_workout_name = resp_data.workout_name
      this.state.workoutCalendar.current_workout_image = (resp_data.workout_image!=null) ? resp_data.workout_image : this.state.workoutCalendar.current_workout_image
      this.state.workoutCalendar.current_workout_description = resp_data.workout_description
      this.state.workoutCalendar.get_exercise = false

      state.total_reps = resp_data.total_reps
      state.total_sets = resp_data.total_sets
      state.total_weight = resp_data.total_weight
      state.warmup = resp_data.warmup
      state.cooldown = resp_data.cooldown
      state.finisher = resp_data.finisher
      state.exerciseList = resp_data.exercise
      state.exerciseList_length = resp_data.exercise.length   

    },
   
    updateField
  },
  getters: {
    getField,
  },
  actions: {
    getModel({ commit, dispatch }) {
      commit("unsetModel")

      let equipment_string=null
      let current_equipments=this.state.workoutCalendar.current_equipments
      if(current_equipments.length > 0){
        // generate_user_workout=1;
        equipment_string=current_equipments.join(',')
      }

      return new Promise((resolve,reject) => {

        let api_name='get_user_workout';
        let api_data=null;
        let api_method='POST';
        api_data={
          workout_type: this.state.workoutCalendar.calendar_type,
          workout_id: this.state.workoutCalendar.current_workout_id,
          user_cycle_id: this.state.workoutCalendar.current_user_cycle_id,
          equipment_array: equipment_string,
          workout_date: this.state.workoutCalendar.calendar_date,
          generate_user_workout: this.state.workoutCalendar.generate_user_workout,
          generate_for_user_id: this.state.workoutCalendar.get_for_user_id,
          generate_for_team_id: this.state.workoutCalendar.get_for_team_id,
        };

        axios({
          method: api_method,
          url: api_name,
          params: api_data
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModel", response_data);
              resolve(resp)
            }
        }).catch(error => {
            commit("unsetModel")
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    getWFC({ commit, dispatch }) {
      commit("unsetWFC")
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'get_user_wfc',
          params: {
            wfc_type: this.state.workoutExercise.wfc_type,
          }
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setWFC", response_data);
              resolve(resp)
            }
        }).catch(error => {
            commit("unsetWFC")
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
    
  
    addExerciseToWorkout(context,search_exercise_ids) {
      console.log(search_exercise_ids)
      if(search_exercise_ids.length>0){
        for (let exerInfo of Object.values(search_exercise_ids)){
          let new_Exercise=[];
          let newExercise={
            lw_exercise_id : 0,
            user_workout_exercise_id : 'A',
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            checkmark_status : false,
            rest_time : 0,
            exercise_set_count : 0,
            reps_type : 'REPS',
            weight_type : 'AMOUNT',
            sets : [],
          }
          new_Exercise.push(newExercise)
          this.state.workoutExercise.exerciseList.push(new_Exercise)
        }
        this.state.workoutExercise.exerciseList_length=this.state.workoutExercise.exerciseList.length
        router.go(-1);
      }
    }, 
  
    superExerciseToWorkout(context,search_exercise_ids) {
     
      if(search_exercise_ids!=null){
        let exerInfo=search_exercise_ids
        let newExercise={
          lw_exercise_id : 0,
          user_workout_exercise_id : 'A',
          exercise_id : exerInfo.exercise_id,
          exercise_name : exerInfo.exercise_name,
          exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
          exercise_large_image : exerInfo.exercise_image_thumbnail,
          exercise_video_url : exerInfo.exercise_image_thumbnail,
          checkmark_status : false,
          rest_time : 0,
          exercise_set_count : 0,
          reps_type : 'REPS',
          weight_type : 'AMOUNT',
          sets : [],
        }
        this.state.workoutExercise.exerciseList[this.state.workoutExercise.exer_index][this.state.workoutExercise.super_index+1]=newExercise
        this.state.workoutExercise.data_change=true
        router.go(-2);
      }
    }, 

    swapExerciseToWorkout(context,search_exercise_ids) {
     
      if(search_exercise_ids!=null){
        let exerInfo=search_exercise_ids
        let oldExerInfo=this.state.workoutExercise.exerciseList[this.state.workoutExercise.exer_index][this.state.workoutExercise.super_index]
        let newExercise={
          lw_exercise_id : oldExerInfo.lw_exercise_id,
          user_workout_exercise_id : oldExerInfo.user_workout_exercise_id,
          exercise_id : exerInfo.exercise_id,
          exercise_name : exerInfo.exercise_name,
          exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
          exercise_large_image : exerInfo.exercise_image_thumbnail,
          exercise_video_url : exerInfo.exercise_image_thumbnail,
          checkmark_status : false,
          rest_time : 0,
          exercise_set_count : 0,
          reps_type : 'REPS',
          weight_type : 'AMOUNT',
          sets : [],
        }
        this.state.workoutExercise.exerciseList[this.state.workoutExercise.exer_index][this.state.workoutExercise.super_index]=newExercise
        this.state.workoutExercise.data_change=true
        router.go(-2);
      }
    }, 


     /* DO NOT DELETE THIS FUNCTION */
   /*  swapWFCToWorkout(context,search_exercise_ids) {
      let newWFC=null
      if(Object.keys(search_exercise_ids).length>0){
        let wfcInfo=search_exercise_ids
        newWFC={
          id : wfcInfo.id,
          name : wfcInfo.name,
          image : wfcInfo.image,
          description : null,
          no_of_rounds : 0,
          rest_time : 0,
          exercises : [],
        }
      }
      if(this.state.bottomSearch.wfc_type=='WARMUP'){
        this.state.workoutExercise.warmup=newWFC
      }
      if(this.state.bottomSearch.wfc_type=='COOLDOWN'){
        this.state.workoutExercise.cooldown=newWFC
      }
      if(this.state.bottomSearch.wfc_type=='FINISHER'){
        this.state.workoutExercise.finisher=newWFC
      } 
      this.state.workoutExercise.data_change=true
      if(this.state.workoutExercise.is_team_wfc){
        router.push({ path: '/teamworkoutexercise' });
      }else{
        router.go(-1) ;
      } 
    },  */

  },
};
<template>
<div class="Organization">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <h1 class="avidocolor--text mb-3">{{name}} Organization</h1>
        <v-divider class="white"></v-divider>
      </v-col>
    </v-row>
    
    <v-card flat class="pa-8">
      <v-form
        ref="organizationform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >

        <v-text-field
          v-model="name"
          class="pa-md-4"
          prepend-icon="mdi-account"
          name="organization_name"
          label="Organization Name"
          color="avidocolor"
          :rules="[(v) => !!v || 'Organization Name is required']"
        ></v-text-field>

        <v-textarea
          v-model="description"
          required
          class="pa-md-4"
          prepend-icon="mdi-checkbox-marked-circle-outline"
          name="description"
          color="avidocolor"
          :rules="[(v) => !!v || 'Organization Description is required']"
        >
          <template v-slot:label>
            <div>Description</div>
          </template>
        </v-textarea>

        <v-text-field
          v-model="organization_join_code"
          class="pa-md-4"
          prepend-icon="mdi-qrcode"
          name="organization_join_code"
          label="Organization Join Code For Coach"
          color="avidocolor"
          :rules="[(v) => !!v || 'Organization Join Code is required']"
        ></v-text-field>
            
      <v-list>
          <v-subheader>Organization's Teams</v-subheader>
          <v-divider></v-divider>
          
          <v-list-item
            v-for="(team,tindex) in organization_teams"
            :key="tindex+team.name"                     
          >
            <v-list-item-avatar tile size=50>
            <v-img class="rounded" alt="team.name" :src="(team.image!=null)?team.image:'/images/logo.jpg'"  @error="team.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left align-self-start">
              <v-list-item-title v-text="team.name"  class="avidocolor--text"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteteam(tindex,team.id)"
                    >
                      <v-icon color="red">mdi-minus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Leave This Team</span>
                </v-tooltip>
            </v-list-item-action>
          </v-list-item>

          <v-subheader>Organization's Coaches</v-subheader>
          <v-divider></v-divider>
          
          <v-list-item
            v-for="(coach,cindex) in organization_coaches"
            :key="cindex+coach.id"            
          >
            <v-list-item-avatar tile size=50>
            <v-img class="rounded" alt="coach.name" :src="(coach.profile_pic!=null)?coach.profile_pic:'/images/logo.jpg'"  @error="coach.profile_pic='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left align-self-start">
              <v-list-item-title v-text="coach.name"  class="avidocolor--text"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="deletecoach(cindex,coach.id)"
                    >
                      <v-icon color="red">mdi-minus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Leave This Coach</span>
                </v-tooltip>
            </v-list-item-action>
          </v-list-item>

        </v-list>


        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor"> <v-icon>mdi-content-save</v-icon>save  </v-btn>
        </v-card-actions>


      </v-form> 
    </v-card>  

  </div>
</template>




<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Team",
  data: () => ({
    valid: false,
  }),
  computed:{
    ...mapFields('organization',{
      organization_id:'organization_id',
      name:'name',
      description:'description',
      organization_join_code:'organization_join_code',
      organization_teams:'organization_teams',
      organization_coaches:'organization_coaches',
      organization_team_ids:'organization_team_ids',
      coach_user_ids:'coach_user_ids',
    })
  },
  methods: {
    ...mapActions('organization',{
      getOrganizationModel:'getOrganizationModel',
      updateOrganizationModel:'updateOrganizationModel',
    }),
    deleteteam(remove_index,team_id){
      this.organization_team_ids=this.organization_team_ids.filter(team => team !== team_id)
      this.organization_teams.splice(remove_index, 1);      
    },
    deletecoach(remove_index,coach_user_id){
      this.coach_user_ids=this.coach_user_ids.filter(coach => coach !== coach_user_id)
      this.organization_coaches.splice(remove_index, 1);      
    },
    onSubmit: function () {
      if (this.$refs.organizationform.validate()) {
        this.updateOrganizationModel();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.getOrganizationModel();
  },
};
</script>
<template>
  <div class="organizationProfile">

    <v-row align="center" justify="center">
      <v-col>
        <v-avatar size="200">
          <img :alt="organization_name" src="../../assets/logo.jpg" />
        </v-avatar>
      </v-col>
    </v-row>

    <v-list color="black" class="pa-0">
        <v-row>
          <v-col cols="6" class="pa-2">TEAMS: {{total_teams}}</v-col>
          <v-col cols="6" class="pa-2">COACHES: {{total_coaches}}</v-col>
        </v-row>
        <v-divider></v-divider>
      </v-list>

      <template v-if="organization_coaches.length > 0">
        <div class="text-left text-body-1 pt-2">Teams</div> 
        <v-list>
          <template v-for="(team, i) in organization_teams">
          <v-list-item
            :key="i"
            to="/team-profile"
          >
            <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="team.name" :src="(team.image!=null)?team.image:'/images/logo.jpg'" @error="team.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
              <v-list-item-title v-text="team.name"  class="avidocolor--text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="i < organization_teams.length - 1"
              :key="i+team.id+i" ></v-divider>
          </template>    
        </v-list>
      </template>

      <template v-if="organization_coaches.length > 0">
        <div class="text-left text-body-1 pt-2">Coaches</div> 
        <v-list>
          <template v-for="(user, oindex) in organization_coaches">
          <v-list-item
            :key="oindex"
            @click=get_profile(user.id,user.name)
          >
              <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="user.name" :src="(user.profile_pic!=null)?user.profile_pic:'/images/logo.jpg'" @error="user.profile_pic='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title v-text="user.name"  class="avidocolor--text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="oindex < organization_coaches.length - 1"
              :key="oindex+user.id+oindex" ></v-divider>
          </template>  
        </v-list>
      </template>  

      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
            <v-btn @click="getTeamWorkout(team_id)"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-plus-box</v-icon>Workouts
            </v-btn>

            <v-btn @click="getTeamResult(team_id)"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-plus-box</v-icon>Results
            </v-btn>
 
            <v-btn to="/join-organization"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-plus-box</v-icon>Join Organization
            </v-btn>
        </v-row>
      </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "OrganizationProfile",
  computed:{
    ...mapFields('organizationProfile',{      
      organization_name:'organization_name',
      total_teams:'total_teams',
      total_coaches:'total_coaches',
      organization_teams:'organization_teams',
      organization_coaches:'organization_coaches',
      fromseacrh:'fromseacrh',
    })
  },
  methods: {
    ...mapActions('organizationProfile',{
      getOrganizationProfileModel:'getOrganizationProfileModel',
    }),
    get_profile(user_id,username){
      this.$router.push({ path: 'user-profile?uid='+user_id+'&uname='+username });
    },
  },
  created() {
    this.getOrganizationProfileModel();
  },
};
</script>
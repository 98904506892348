import axios from "axios";
import { getField } from 'vuex-map-fields';
 
export default {
  namespaced: true,
  state: {
    get_api_call: true,
    upgrade_to_trainer: false,
    my_teams_asmember: [],
    my_teams_ascoach: [],
    my_trainers: [],
    my_clients: [],
    my_organization_ascoach: [],
  },
  
  getters: {
    getField,
  },

  mutations: {
    setModelState(state, response_data) {
      state.get_api_call = false
      state.upgrade_to_trainer = response_data.upgrade_to_trainer
      state.my_teams_asmember = response_data.my_teams_asmember
      state.my_teams_ascoach = response_data.my_teams_ascoach
      state.my_trainers = response_data.my_trainers
      state.my_clients = response_data.my_clients
      state.my_organization_ascoach = response_data.my_organization_ascoach
    },
  },

  actions: {
    getMyTeamTrainerModel({ commit, dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'get_users_connections',
          params: {
            type: 'ALL'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
  },

};
<template>
  <div id="app">
    <v-app>
      <HeaderComponent msg="Welcome to Avido"/>
      <Notification />
      <Loader  v-if="isLoading" />
       <v-container :fluid="component_name=='HOME' || component_name=='COACHES' ? true : false">
          <router-view/>
       </v-container>

    </v-app>      
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/HeaderComponent.vue'
import Notification from '@/components/Notification.vue'
import Loader from '@/components/Loader.vue'
import {initSession} from "./session-manager";
import { mapState } from 'vuex';

 export default {
    components: {
      HeaderComponent,
      Notification,
      Loader
    },  
    computed: {
      ...mapState(['component_name','isLoading'])
    },
    mounted() {
       this.$vuetify.theme.themes.dark.avidocolor = (typeof(localStorage.getItem("dark_text_color")) != "undefined" && localStorage.getItem("dark_text_color") !== null) ? localStorage.getItem("dark_text_color") : '#52F60C';
       initSession();  //Initialize our session
    },
  }

</script>
      
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

.headingclass
{
  height: 100vh; /*THis will set height equal to the height of windows*/
  width: 100vw; /*This will set width equal to width of windows*/
}

#nav a {
  color: #ffffff;
  padding: 0 10px;
  text-decoration: none;
}

#nav a.router-link-exact-active {
   color: #52F60C;
}

.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title{
  white-space: normal;
}
.clear { clear: both; height: 200px; }

.setinput input {
    max-height: 32px !important;
    font-size: 1.2em !important;
    text-align: center !important;
}
.setinput.v-text-field>.v-input__control>.v-input__slot:before{
  border-color: transparent !important;
}
.v-card__text, .v-card__title {
   word-break: break-word !important; 
}
/* 
.v-bottom-navigation .v-btn {
  border-radius: 4px !important;
  height: 36px !important;
  font-size: initial  !important;
  text-transform: uppercase !important;
}
.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: initial !important;
  font-weight: bolder;
} */

</style>

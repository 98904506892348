import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    api_name:null,
    api_data:null,
    library_tab:'WORKOUT',
    library_id:0,
    library_name:null,
    library_description:null,
    library_image:null,
    imagePreviewURL:null,
    library_array:[],
    library_workout_list:[],
    library_cycle_list:[],
    library_program_list:[],
    library_warmup_list:[],
    library_finisher_list:[],
    library_cooldown_list:[],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    setModelState(state, {response_data,tab_type}) {
      
      if(tab_type=='WORKOUT') state.library_array=state.library_workout_list=response_data
      else if(tab_type=='CYCLE') state.library_array=state.library_cycle_list = response_data
      else if(tab_type=='PROGRAM') state.library_array=state.library_program_list = response_data
      else if(tab_type=='WARMUP') state.library_array=state.library_warmup_list = response_data
      else if(tab_type=='FINISHER') state.library_array=state.library_finisher_list = response_data
      else if(tab_type=='COOLDOWN') state.library_array=state.library_cooldown_list = response_data
    },

    reSetModelState(state) {
      state.library_id=0
      state.api_name=null
      state.api_data=null
      state.library_name=null
      state.library_description=null
      state.library_image=null
      state.imagePreviewURL=null
      state.library_array=[]
      state.library_workout_list=[]
      state.library_cycle_list=[]
      state.library_program_list=[]
      state.library_warmup_list=[]
      state.library_finisher_list=[]
      state.library_cooldown_list=[]
    },

  },

  actions: {

    getLibraryModel({ commit },[tab_type,resulttype=null,selectedID=0]) {
      /* console.log(tab_type)
      console.log(resulttype)
      console.log(selectedID)   */
      let libraryWarmupList=this.state.library.library_warmup_list
      let libraryFinisherList=this.state.library.library_finisher_list
      let libraryCooldownList=this.state.library.library_cooldown_list
      let libraryWorkoutList=this.state.library.library_workout_list
      let libraryCycleList=this.state.library.library_cycle_list
      let libraryProgramList=this.state.library.library_program_list

      if(tab_type=='WARMUP' && resulttype=='LADDWARMUP'  && libraryWarmupList.length>0){
        this.state.bottomSearch.wfc_type=tab_type
        this.state.bottomSearch.result_type=resulttype
        this.state.bottomSearch.selected_id=selectedID
        router.push({ path: '/bottom-search-exercise' });
      }else if(tab_type=='FINISHER' && resulttype=='LADDFINISHER' && libraryFinisherList.length>0){
        this.state.bottomSearch.wfc_type=tab_type
        this.state.bottomSearch.result_type=resulttype
        this.state.bottomSearch.selected_id=selectedID
        router.push({ path: '/bottom-search-exercise' });
      }else if(tab_type=='COOLDOWN' && resulttype=='LADDCOOLDOWN' && libraryCooldownList.length>0){
        this.state.bottomSearch.wfc_type=tab_type
        this.state.bottomSearch.result_type=resulttype
        this.state.bottomSearch.selected_id=selectedID
        router.push({ path: '/bottom-search-exercise' });
      }else if(tab_type=='WORKOUT' && (resulttype=='LADDWORKOUTS' || resulttype=='ADDWORKOUTS') && libraryWorkoutList.length>0){
        this.state.bottomSearch.result_type=resulttype
        this.state.bottomSearch.selected_id=selectedID
        router.push({ path: '/bottom-search-exercise' });
      }else if(tab_type=='CYCLE' && (resulttype=='LADDCYCLE' || resulttype=='ADDCYCLE') && libraryCycleList.length>0){
        this.state.bottomSearch.result_type=resulttype
        this.state.bottomSearch.selected_id=selectedID
        router.push({ path: '/bottom-search-exercise' });
      }else if(tab_type=='PROGRAM' && (resulttype=='LADDPROGRAM' || resulttype=='ADDPROGRAM') && libraryProgramList.length>0){
        this.state.bottomSearch.result_type=resulttype
        this.state.bottomSearch.selected_id=selectedID
        router.push({ path: '/bottom-search-exercise' });
      }else{
        return new Promise((resolve) => {
          if(tab_type=='WORKOUT' || tab_type=='CYCLE' || tab_type=='PROGRAM'){
            this.api_name='get_user_library';
            this.api_data={
              library_type: tab_type
            };
          }
  
          if(tab_type=='WARMUP' || tab_type=='FINISHER' || tab_type=='COOLDOWN'){
            this.api_name='get_user_wfc';
            this.api_data={
              wfc_type: tab_type
            };
          }
  
          axios({
            method: 'GET',
            url: this.api_name,
            params: this.api_data
          })
            .then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                const response_data = resp.data.response
                commit("setModelState", {response_data,tab_type});
                if(resulttype!=null){
                  this.state.bottomSearch.wfc_type=tab_type
                  this.state.bottomSearch.result_type=resulttype
                  this.state.bottomSearch.selected_id=selectedID
                  router.push({ path: '/bottom-search-exercise' });
                }
                resolve(resp)
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 401 || resp_code == 404) {
                commit("reSetModelState");
              }
            })
        })
      }
     
    },
    
    deleteLibraryModel({ dispatch },model) {
      return new Promise((resolve, reject) => {

        let tab_type=this.state.library.library_tab
        if(tab_type=='WORKOUT'){
          this.api_name='delete_library_workout';
          this.api_data={
            library_workout_id: model.delete_library_id
          };
        }

        if(tab_type=='CYCLE'){
          this.api_name='delete_library_cycle';
          this.api_data={
            library_cycle_id: model.delete_library_id
          };
        }

        if(tab_type=='PROGRAM'){
          this.api_name='delete_library_program';
          this.api_data={
            library_program_id: model.delete_library_id
          };
        }

        if(tab_type=='WARMUP' || tab_type=='FINISHER' || tab_type=='COOLDOWN'){
          this.api_name='delete_wfc';
          this.api_data={
            wfc_ids: model.delete_library_id
          };
        }

        axios({
          method: 'POST',
          url: this.api_name,
          params: this.api_data
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });        
              
              this.state.library.library_array.splice(model.remove_index, 1);      

              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
    
  },

};
<template>
<div class="joincoachtrainer">

    <v-card flat class="pa-8">
      <v-form
        ref="trainerJoinform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
     
        <v-text-field
          class="pa-md-4"
          prepend-icon="mdi-pencil"
          name="athlete_join_code"
          label="Enter Athlete Join Code"
          color="avidocolor"
          :rules="[(v) => !!v || 'Athlete Join Code is required']"
        ></v-text-field>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor"> Join </v-btn>
        </v-card-actions>

      </v-form> 
    </v-card>  

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "JoinCoachTrainer",
  data: () => ({
    valid: false,
    covalid: false,
  }),
  computed:{
    ...mapFields('userProfile',{
      athlete_join_code:'athlete_join_code',
    })
  },
  methods: {
    ...mapActions('userProfile',{
      joinTrainer:'joinTrainer',      
    }),
    onSubmit: function() {
      if (this.$refs.trainerJoinform.validate()) {
        this.joinTrainer();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
};
</script>
<template>
  <div class="libraryexerciseset">

      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <h1 class="avidocolor--text mb-3 d-inline">{{current_exercise_name}}</h1>
          <v-icon @click="exerciseInfo()" class="ml-2" style="vertical-align: baseline;"  color="avidocolor" dark >
            mdi-information
          </v-icon>
          <v-divider class="white"></v-divider>
        </v-col>
      </v-row>

      <v-container class="pa-0 fill-height" fluid style="min-height: 250px">
        <v-fade-transition mode="out-in">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-card>
                <v-img
                  :src="image_large"
                  max-height="600"
                  class="grey darken-4"
                  @error="image_large='/images/logo.jpg'"
                ></v-img>

                <v-list color="black" class="pa-0">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" class="pa-0">
                      <v-list-item-action class="ma-0 align-center">
                        <div class="text-body-1 pt-2 grey--text">Rest Time</div>          
                        <div id="auto-dropdown-containter">
                          <vue-timepicker 
                            v-model="lexerciseList[exer_index][super_index]['rest_time']" 
                            format="m:ss" 
                            :second-interval="5"
                            close-on-complete
                            auto-scroll
                            drop-direction="auto"
                            container-id="auto-dropdown-containter"
                            append-to-body
                            @change="restTimeChange"
                            v-on:change="data_change==false ? data_change=!data_change : data_change"
                          >
                          </vue-timepicker>
                        </div>
                      </v-list-item-action>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-fade-transition>

        <v-row v-if="exercise_sets.length>0">
          <v-col cols="12" class="pt-0 pb-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center text-body-1">SETS</th>
                    <th class="text-center text-body-1">REPS</th>
                    <th class="text-center text-body-1">
                      <v-select
                        v-model="lexerciseList[exer_index][super_index]['weight_type']"
                        :items="weight_type_options"
                        item-value="weight_type" 
                        item-text="display_weight_type" 
                        color="avidocolor"
                        item-color="avidocolor"
                        class="setinput"
                        single-line
                      ></v-select>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in exercise_sets"  :key="item.id">

                    <td>
                       <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="red"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click.stop.prevent="
                                deleteSet(index)
                              "
                            >
                              <v-icon color="red">mdi-minus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete This Set</span>
                        </v-tooltip>
                    </td>

                    <td>{{ index+1 }}</td>
                    <td>
                      <!-- <v-edit-dialog> {{ item.reps }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="exercise_sets[index].reps"
                            v-on:change="data_change==false ? data_change=!data_change : data_change"
                            label="Edit Reps"
                            single-line
                            color="avidocolor"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog> -->
                      <v-text-field
                        v-model="exercise_sets[index].reps"
                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                        placeholder="0"
                        class="setinput"
                        color="avidocolor"
                      ></v-text-field>
                    </td>
                    <td>
                      <!-- <v-edit-dialog>
                         {{ item.weight }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="exercise_sets[index].weight"
                            v-on:change="data_change==false ? data_change=!data_change : data_change"
                            label="Edit Weight"
                            single-line
                            color="avidocolor"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog> -->
                        <v-text-field
                          v-model="exercise_sets[index].weight"
                          v-on:change="data_change==false ? data_change=!data_change : data_change"
                          placeholder="0"
                          class="setinput"
                          color="avidocolor"
                        ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-col>
        </v-row>

      </v-container>

      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>

            <v-btn @click="exerciseSearch('LSWAPEXERCISE')"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-swap-horizontal</v-icon>Swap
            </v-btn>
     
            <v-btn @click="addSets()"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-plus-box</v-icon>Add Set
            </v-btn>

            <v-btn @click="$router.go(-1)" class="my-2 avidocolor--text" text>
              <v-icon>mdi-content-save</v-icon>save 
            </v-btn>
          
        </v-row>
      </v-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    VueTimepicker
  },
  data () {
    return {
      dialog: false,
    }
  },
  computed:{
    ...mapFields('libraryExerciseSet',[
      'current_exercise_id', 
      'current_exercise_name', 
      'image_large', 
      'exercise_sets'
    ]),   
    ...mapFields('libraryExercise',{
      exer_index:'exer_index',
      super_index:'super_index',
      data_change:'data_change',
      lexerciseList:'lexerciseList',
      weight_type_options:'weight_type_options',
    }),
    ...mapFields('exerciseInstruction',{
      exercise_instruction_id:'exercise_instruction_id',
    }),
    ...mapFields('bottomSearch',{
      result_type:'result_type',
    }),
  },
  methods: {
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
    }),
    exerciseInfo(){
      this.exercise_instruction_id=this.current_exercise_id
      this.$router.push({ path: 'exercise-instruction' });
    },
    exerciseSearch(result_type){
      this.result_type=result_type
      this.getAvidoExercise(result_type)
    },
    addSets(){
      this.data_change=true
      let current_set_count=this.exercise_sets.length
      let last_resp=null
      let last_weight=null
      if(current_set_count>0){
         last_resp=this.exercise_sets[current_set_count-1].reps
         last_weight=this.exercise_sets[current_set_count-1].weight
      }
      let new_set={
        reps : last_resp,
        set_id : current_set_count,
        weight : last_weight,
      }
      this.lexerciseList[this.exer_index][this.super_index]['exercise_set_count']=current_set_count+1;
      this.exercise_sets.push(new_set)
    },
    deleteSet(remove_index){
        this.data_change=true
        this.exercise_sets.splice(remove_index, 1);      
        let current_set_count=this.exercise_sets.length  
        this.lexerciseList[this.exer_index][this.super_index]['exercise_set_count']=current_set_count;  
    },
    restTimeChange(eventData){
      this.lexerciseList[this.exer_index][this.super_index]['rest_time']=eventData.displayTime
    },

  },

};
</script>

<template>
  <div class="cycleDetails">
    <v-card color="black">
      <v-container>
          <v-row align="center">
            <v-col cols="5" md="2"  class="py-0">
              <v-avatar            
                color="avidocolor"
                size="160"
                tile
                class="rounded"
              >
              <img :alt="current_cycle_name"  :src="(current_cycle_image!=null) ? current_cycle_image : '/images/bg_gray_logo.jpg'" @error="current_cycle_image='/images/bg_gray_logo.jpg'"> 
              </v-avatar>
            </v-col>
            <v-col cols="7" md="10">
              <v-row>   
                <v-col cols="12" class="py-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Number Of Workouts</v-list-item-subtitle>
                      <v-list-item-title class="text-h4">{{calendar_workouts.length}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-2 text-justify">
              {{current_cycle_description}}
            </v-col>
          </v-row>
        </v-container>  
      </v-card>
      
    <v-card class="mx-auto" width="100%">
      <v-list v-if="calendar_workouts.length>0">
        <template v-for="(model, index) in calendar_workouts">
          <v-list-item :key="model.workout_name">
            <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="model.workout_name" :src="(model.workout_image!=null)?model.workout_image:'/images/logo.jpg'" @error="model.workout_image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left align-self-start">
              <v-list-item-title v-text="model.workout_name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="align-center ma-0">
              <span>  Day <br> <span class="avidocolor--text font-weight-bold mb-1">{{ index+1 }} </span> </span>
            </v-list-item-action>
          </v-list-item>

          <v-divider v-if="index < calendar_workouts.length - 1" :key="index"></v-divider>
        </template>
      </v-list>
    </v-card>
  </div>
</template>


<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "CycleDetails",
  computed:{
    ...mapFields('workoutCalendar',[
      'current_cycle_name', 
      'current_cycle_image', 
      'current_cycle_description', 
      'calendar_workouts', 
    ]), 
  },
};
</script>

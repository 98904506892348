import axios from "axios";
import { getField,updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    calendar_route: 0,
    is_workout_editable: 1, // 0 = for all , 1 for user who login , user coach and trainer
    calendar_type: 'AVIDO',  // LIBRARY,TEAM,AVIDO
    calendar_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    calendar_date_text: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toLocaleString('default', { month: 'short', day: '2-digit' }),
    get_exercise: true,
    get_for_user_id: 0,
    get_for_team_id: 0,
    generate_user_workout: 0,
    current_equipments: [],
    current_cycle_id : 0,
    current_user_cycle_id : 0,
    current_cycle_image : null,
    current_cycle_name : null,
    current_cycle_description : null,
    current_workout_id: 0,
    current_workout_name: null,
    current_workout_image: null,
    current_workout_description: null,
    current_user_workout_id: 0,
    current_user_workout_completed: false,
    week_totals : [],
    calendar_workouts:[],
    user_LWorkouts_list:[],
    assign_library_workout_id:0,
    assign_workout_execution_dates:null,
    user_LCycles_list:[],
    assign_library_cycle_id: 0,
    assign_cycle_start_date: null,
    assign_cycle_end_date: null,
    user_LProgram_list:[],
    assign_library_program_id: 0,
    assign_program_startdate: null,
  },
  mutations: {
    unsetWeekSummary(state) {
      state.calendar_route = 0,
      state.current_user_workout_id = 0,
      state.current_user_workout_completed = false
      state.generate_user_workout = 0,
      state.current_equipments = [],
      state.current_cycle_id = 0,
      state.current_user_cycle_id = 0,
      state.current_cycle_image = null,
      state.current_cycle_name = null,
      state.current_cycle_description = null,
      state.current_workout_id = 0,
      state.current_workout_name = null,
      state.current_workout_image = null,
      state.current_workout_description = null,
      state.week_totals = [],
      state.calendar_workouts = []
    //  state.calendar_date= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //  state.calendar_date_text= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toLocaleString('default', { month: 'short', day: '2-digit' })
    },
    setWeekSummary(state, response_data) {

      state.week_totals = response_data.week_totals
      state.calendar_workouts = response_data.calendar_workouts
      state.is_workout_editable = response_data.is_workout_editable
      state.current_workout_image = response_data.current_displayskin.logo_image
      
      state.calendar_date_text = (new Date(response_data.week_start_date)).toLocaleString('default', { month: 'short', day: '2-digit' })+' - '+(new Date(response_data.week_end_date)).toLocaleString('default', { month: 'short', day: '2-digit' })
      if(response_data.cycle_id>0){
          state.current_cycle_id = response_data.cycle_id
          state.current_cycle_image = response_data.cycle_image
          state.current_cycle_name = response_data.cycle_name
          state.current_cycle_description = response_data.cycle_description
      }    
      if(response_data.library_cycle_id>0){
          state.current_cycle_id = response_data.library_cycle_id
          state.current_cycle_image = response_data.cycle_image
          state.current_cycle_name = response_data.cycle_name
          state.current_cycle_description = response_data.cycle_description
      }
    },

    setLWORKOUTState(state, response_data) {
      state.user_LWorkouts_list = response_data
    },
    resetLWORKOUTState(state) {
      state.assign_library_workout_id = 0
      state.assign_workout_execution_dates = null
      state.user_LWorkouts_list = []
    },
    
    setLCYCLEState(state, response_data) {
      state.user_LCycles_list = response_data
    },
    resetLCYCLEState(state) {
      state.user_LCycles_list = []
      state.assign_library_cycle_id = 0
      state.assign_cycle_start_date = null
      state.assign_cycle_end_date = null
    },

    setLPROGRAMState(state, response_data) {
      state.user_LProgram_list = response_data
    },
    resetLPROGRAMState(state) {
      state.user_LProgram_list = []
      state.assign_library_program_id = 0
      state.assign_program_startdate = null
    },
    updateField
  },
  getters: {
    getField,
  },
  actions: {
    getWeekSumaryModel({ commit, dispatch }) {
      return new Promise((resolve,reject) => {
        let api_name='get_week_summary' 
        let api_data={
          get_for_user_id: this.state.workoutCalendar.get_for_user_id,
          get_for_team_id: this.state.workoutCalendar.get_for_team_id,
          calendar_date: this.state.workoutCalendar.calendar_date
        };

        axios({
          method: 'GET',
          url: api_name,
          params:api_data
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("unsetWeekSummary")
              commit("setWeekSummary", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            console.log(error)
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
 
    getUserLProgramList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_library',
          params: {
            library_type: 'PROGRAM'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setLPROGRAMState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              commit("resetLPROGRAMState");
            }
          })
      })
    },
    
    addLibraryProgramToCalendar({ dispatch }) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'assign_library_program',
          data: {
            addto_user_id: this.state.workoutCalendar.get_for_user_id,
            addto_team_id: this.state.workoutCalendar.get_for_team_id,            
            library_program_id: this.state.workoutCalendar.assign_library_program_id.id,            
            program_start_date: this.state.workoutCalendar.assign_program_startdate,            
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.go(-1);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 

    getUserLCycleList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_library',
          params: {
            library_type: 'CYCLE'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setLCYCLEState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              commit("resetLCYCLEState");
            }
          })
      })
    },

    addLibraryCycleToCalendar({ dispatch }) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'assign_library_cycle',
          data: {
            addto_user_id: this.state.workoutCalendar.get_for_user_id,
            addto_team_id: this.state.workoutCalendar.get_for_team_id,            
            library_cycle_id: this.state.workoutCalendar.assign_library_cycle_id.id,            
            cycle_start_date: this.state.workoutCalendar.assign_cycle_start_date,            
            cycle_end_date: this.state.workoutCalendar.assign_cycle_end_date,            
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.go(-1);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 

    
    getUserLWorkoutsList({ commit }) {
      commit("resetLWORKOUTState");
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_library',
          params: {
            library_type: 'WORKOUT'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setLWORKOUTState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              commit("resetLWORKOUTState");
            }
          })
      })
    },
    
    addLibraryWorkoutToCalendar({ dispatch }) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'assign_library_workout',
          data: {
            addto_user_id: this.state.workoutCalendar.get_for_user_id,
            addto_team_id: this.state.workoutCalendar.get_for_team_id,            
            library_workout_id: this.state.workoutCalendar.assign_library_workout_id.id,            
            workout_execution_dates: this.state.workoutCalendar.assign_workout_execution_dates,            
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.go(-1);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 

  },
};
<template>
  <div class="library">

    <div
      fixed
      color="white"
      hide-on-scroll
      horizontal
      scroll-target="#scroll-threshold-example"
      scroll-threshold="500"
    >

      <v-tabs
        v-model="tab"
        background-color="black"
        color="avidocolor"
        centered
        icons-and-text
        fixed-tabs
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#WORKOUT" @click="library_workout_list.length==0 ? getLibrary('WORKOUT') : changeLibraryArray('WORKOUT')">
          Workouts
        </v-tab>

        <v-tab href="#CYCLE" @click="library_cycle_list.length==0 ? getLibrary('CYCLE') : changeLibraryArray('CYCLE')">
          Cycles
        </v-tab>

        <v-tab href="#PROGRAM" @click="library_program_list.length==0 ? getLibrary('PROGRAM') : changeLibraryArray('PROGRAM')">
          Programs
        </v-tab>
        <v-tab href="#WARMUP" @click="library_warmup_list.length==0 ? getLibrary('WARMUP') : changeLibraryArray('WARMUP')">
          Warm Ups
        </v-tab>
        <v-tab href="#FINISHER" @click="library_finisher_list.length==0 ? getLibrary('FINISHER') : changeLibraryArray('FINISHER')">
          Finishers
        </v-tab>
        <v-tab href="#COOLDOWN" @click="library_cooldown_list.length==0 ? getLibrary('COOLDOWN') : changeLibraryArray('COOLDOWN')">
          Cool Downs
        </v-tab>
      </v-tabs>
    </div>

  <v-row>
    <v-col cols="12">
      
      <v-card id="scroll-threshold-example">
  
        <v-alert v-if="library_array.length == 0"
          type="error"
          dense
          outlined>
          Record Not Found.
        </v-alert>

       <v-list v-if="library_array.length > 0">
          <template v-for="(model, index) in library_array">
            <v-list-item
              :key="model.name+index"
              @click="getLibraryExercise(model)"
            >
              <v-list-item-avatar tile size=50>
               <v-img class="rounded"
                  :alt="model.name"
                  :src="
                    model.image != null ? model.image : '/images/logo.jpg'
                  "
                  @error="model.image = '/images/logo.jpg'"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title v-html="model.name" class="wrap-text"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-row>
                  <v-col class="pa-0">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                           @click.stop="dialog = true"
                        >
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete This {{ tab }} Library</span>
                      <v-dialog
                        v-model="dialog"
                        max-width="300"
                      >
                        <v-card>

                          <v-card-text class="red--text">
                            Do you want to permenantly delete 
                          </v-card-text>

                          <v-card-title>
                            {{model.name}}
                          </v-card-title>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog = false"
                            >
                              No
                            </v-btn>

                            <v-btn
                              color="avidocolor"
                              text
                              @click.stop.prevent="
                                deleteLibraryModel({
                                  remove_index: index,
                                  delete_library_id: model.id,
                                })
                              "
                            >
                              Yes
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                    </v-tooltip>
                  </v-col>
                  <!-- <v-col v-if="tab!='WORKOUT'" class="pa-0">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click.stop.prevent="
                            editLibraryModel(model.id)
                          "
                        >
                          <v-icon color="avidocolor"
                            >mdi-pencil-box-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit This {{ tab }} Library</span>
                    </v-tooltip>
                  </v-col> -->
                </v-row>

              </v-list-item-action>
            </v-list-item>

            <v-divider
              v-if="index < library_array.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-col>
  </v-row>

  <div class="clear"></div>
  <v-footer color="black" fixed padless>
    <v-row justify="center" no-gutters>
      <v-btn @click="newLibrary()"  class="my-2 avidocolor--text" text >
        <v-icon>mdi-plus-box</v-icon>Create {{tab}}
      </v-btn>
    </v-row>
  </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Team",
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapFields("library", {
      library_id: "library_id",
      library_name: "library_name",
      tab: "library_tab",
      library_array: "library_array",
      library_workout_list: "library_workout_list",
      library_cycle_list: "library_cycle_list",
      library_program_list: "library_program_list",
      library_warmup_list: "library_warmup_list",
      library_finisher_list: "library_finisher_list",
      library_cooldown_list: "library_cooldown_list",
    }),
  },
  methods: {
    ...mapActions("library", {
      getLibraryModel: "getLibraryModel",
      deleteLibraryModel: "deleteLibraryModel",
    }),

    newLibrary(){
      this.$store.commit("library/reSetModelState");
      this.$store.commit("libraryExercise/reSetModelState");
      this.$store.commit('changeHeaderTitle','CREATE '+this.tab);
      if(this.tab=='WORKOUT'){
        this.$router.push({ path: 'edit-lwe' });
      }else{
        this.$router.push({ path: 'library-form' });
      }
    },

    getLibraryExercise(library) {
      this.library_id = library.id;
      this.library_name = library.name;
      this.$router.push({ path: "library-exercise" });
    },
    
    editLibraryModel(library_id){
      this.library_id=library_id
      this.$router.push({ path: 'library-form' });
    },

    getLibrary(tab_type){
      this.$store.commit('changeHeaderTitle',tab_type+' LIBRARY');
      this.getLibraryModel([tab_type,null,0]);
    },

    changeLibraryArray(tab_type){
      this.$store.commit('changeHeaderTitle',tab_type+' LIBRARY');
      if(tab_type=='WORKOUT' && this.library_workout_list.length>0) this.library_array=this.library_workout_list
      else if(tab_type=='CYCLE' && this.library_cycle_list.length>0) this.library_array=this.library_cycle_list
      else if(tab_type=='PROGRAM' && this.library_program_list.length>0) this.library_array=this.library_program_list
      else if(tab_type=='WARMUP' && this.library_warmup_list.length>0) this.library_array=this.library_warmup_list
      else if(tab_type=='FINISHER' && this.library_finisher_list.length>0) this.library_array=this.library_finisher_list
      else if(tab_type=='COOLDOWN' && this.library_cooldown_list.length>0) this.library_array=this.library_cooldown_list
    }

  },
  created() {
    var get_list=0
    var tab_type=this.tab
    if(tab_type=='WORKOUT' && this.library_workout_list.length==0){
      get_list=1
    }
    else if(tab_type=='CYCLE' && this.library_cycle_list.length==0){
      get_list=1
    }
    else if(tab_type=='PROGRAM' && this.library_program_list.length==0){
      get_list=1
    }
    else if(tab_type=='WARMUP' && this.library_warmup_list.length==0){
      get_list=1
    }
    else if(tab_type=='FINISHER' && this.library_finisher_list.length==0){
      get_list=1
    } 
    else if(tab_type=='COOLDOWN' && this.library_cooldown_list.length==0){
      get_list=1
    }

    if(get_list){
      this.getLibraryModel([this.tab,null,0]);
    }else{
      if(tab_type=='WORKOUT') this.library_array=this.library_workout_list
      else if(tab_type=='CYCLE') this.library_array=this.library_cycle_list
      else if(tab_type=='PROGRAM') this.library_array=this.library_program_list
      else if(tab_type=='WARMUP') this.library_array=this.library_warmup_list
      else if(tab_type=='FINISHER') this.library_array=this.library_finisher_list
      else if(tab_type=='COOLDOWN') this.library_array=this.library_cooldown_list
    }
  },
};
</script>

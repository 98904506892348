import axios from "axios";
import { getField,updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    organization_id: 0,
    organization_name: null,
    organization_description: null,
    organization_join_code: null,
    total_teams: 0,
    total_coaches: 0,
    fromseacrh: 0,
    organization_teams: [],
    organization_coaches: [],
  },

  getters: {
    getField,
  },

  mutations: {
    setModelState(state, response_data) {
      state.organization_name = response_data.name      
      state.organization_description = response_data.description
      state.total_teams = response_data.teams.length
      state.total_coaches = response_data.coaches.length
      state.organization_teams = response_data.teams
      state.organization_coaches = response_data.coaches
    },
    updateField
  },

  actions: {
    getOrganizationProfileModel({ commit, dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'view_organization_details',
          params: {
            organization_id: this.state.organizationProfile.organization_id
          }
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    joinOrganization({ dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'POST',
          url: 'coach_join_organization',
          data: {
            organization_id: this.state.organizationProfile.organization_id,
            organization_join_code: this.state.organizationProfile.organization_join_code,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const success_mesg = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true })
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 

  },

};
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    result_type: 'USER',
    search_id: 0,
    search_model: null,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
  },

};
<template>
  <div class="userProfile">
    <v-row align="center" justify="center">
      <v-col>
        <v-avatar size="200">
          <v-img :alt="user_name" :src="(profile_url!=null)?profile_url:'/images/logo.jpg'" @error="profile_url='/images/logo.jpg'"></v-img>
        </v-avatar>
      </v-col>
    </v-row>

    <v-list color="black" class="pa-0">
      <v-row>
          <v-col cols="4" class="pa-2">{{total_reps}} <br/>Repetations</v-col>
          <v-col cols="4" class="pa-2">{{total_weight}} <br/>Pounds</v-col>
          <v-col cols="4" class="pa-2">{{total_workouts}} <br/>Workouts</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="text-left align-self-start">
            <v-list-item-title>
              <span class="font-weight-bold">Bio / Goals:</span>
              <span class="text-wrap">
               {{bio}}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        
      </v-list>


      <template v-if="my_teams.length > 0">
        <div class="text-left text-body-1 pt-2">Teams</div> 
        <v-list>
          <template v-for="(team, mindex) in my_teams">
            <v-list-item
              :key="mindex+team.id"
                @click="getTeamProfile(team.id)"
            >
              <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="mindex+team.name" :src="(team.image!=null)? team.image:'/images/logo.jpg'" @error="team.profile_pic='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>
              
              <v-list-item-content class="text-left">
                <v-list-item-title v-text="team.name"  class="avidocolor--text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="mindex < my_teams.length - 1"
              :key="mindex+'_'+team.id" ></v-divider>
          </template>  
        </v-list>
      </template>

    
      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
          <v-btn @click="getworkoutcalendar()"  class="my-2 avidocolor--text" text >
            <v-icon>mdi-eye</v-icon>Workouts
          </v-btn>
          <v-btn  @click="getUserResult()"  class="my-2 avidocolor--text" text >
            <v-icon>mdi-chart-bar</v-icon>Results
          </v-btn>
        </v-row>
      </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "UserProfile",
  data () {
    return {
      get_user_profile_id:this.$route.query.uid,
      get_user_profile_name:this.$route.query.uname,
    }
  },
  computed:{
    ...mapFields('userProfile',{
      user_id:'user_id',
      user_name:'user_name',
      profile_url:'profile_url',
      bio:'bio',
      total_reps:'total_reps',
      total_weight:'total_weight',
      total_workouts:'total_workouts',
      my_teams:'my_teams',
    }),
    ...mapFields('results',{
      result_user_id:'user_id',
      result_team_id:'team_id'
    }),
    ...mapFields('teamProfile',{
      team_id:'team_id',
    }),
  },
  methods: {
    ...mapActions('userProfile',{
      getuserProfileModel:'getuserProfileModel'      
    }),
    ...mapActions('teamProfile',{
      getTeamProfileModel:'getTeamProfileModel',
    }),
    getTeamProfile(teamid) {
      this.team_id = teamid;
      this.$router.push({ path: 'team-profile' });
    },  
    getworkoutcalendar(){
      this.$router.push({ path: 'week-summary?uid='+this.user_id+'&tid=0' });
    },
    getUserResult(){
      this.result_user_id = this.user_id;
      this.result_team_id = 0;
      this.$router.push({ path: 'results' });
    }
  }, 
  created() {
    if(this.get_user_profile_id!=undefined){
      this.user_id=this.get_user_profile_id
      this.user_name=this.get_user_profile_name
    }
    if(this.get_user_profile_id==undefined){
      this.user_id=0
      this.user_name=localStorage.getItem('user_name')
    }    
    this.$store.commit('changeHeaderTitle',this.user_name);
    this.getuserProfileModel();
    
    this.$vuetify.theme.themes.dark.avidocolor = (typeof(localStorage.getItem("dark_text_color")) != "undefined" && localStorage.getItem("dark_text_color") !== null) ? localStorage.getItem("dark_text_color") : '#52F60C';
  },
};
</script>

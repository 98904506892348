<template>
  <div class="workoutwfc">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <h1 class="avidocolor--text mb-3">{{wfcName}}</h1>
        <v-divider class="white"></v-divider>
      </v-col>
    </v-row>

    <v-card class="mx-auto" width="100%">
      <v-list color="black" class="pa-0">
        <v-list-item>
          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="wfcName" :src="(wfcImage!=null)?wfcImage:'/images/logo.jpg'" @error="wfcImage='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="text-left align-self-start">
            <v-list-item-title>
              <span class="font-weight-bold">Description:</span>
              <span class="text-wrap">
                {{wfcDescription}}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="6">
            No of rounds : <span class="avidocolor--text" > {{wfcRoundOfTime}}</span>
          </v-col>

          <v-col cols="6">
            Rest Time : <span class="avidocolor--text" > {{wfcRestTime}}</span>           
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-list>

      <v-list v-if="wfcExercise!=null && wfcExercise.length>0">
            <template v-for="(model,index) in wfcExercise">
              <v-list-item :key="index+model.wfc_exercise_id+model.exercise_id">
                 <v-list-item-avatar tile size=50>
            <v-img class="rounded"  :alt="model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title
                    class="align-center"
                    v-text="model.exercise_name"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="avidocolor--text align-center ma-0">
                  {{ model.reps_type }}
                  <v-list-item-action-text>
                    <p class="font-weight-bold mb-1" style="font-size: 1.25rem">
                      {{ model.reps }}
                    </p>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < wfcExercise.length - 1"
              :key="index" ></v-divider>
            </template>
      </v-list>

    </v-card>

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
          <v-btn v-on:click="calendar_route=calendar_route-1"  @click="$router.go(-1)" class="my-2 avidocolor--text" text >
            <v-icon>mdi-chevron-left</v-icon>Back
          </v-btn>
          <v-btn @click="nextPrevious()" class="my-2 avidocolor--text" text >
            Next<v-icon>mdi-chevron-right</v-icon>
          </v-btn>
      </v-row>
    </v-footer>

  </div>
</template>


<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "WorkoutWFC",
  data () {
    return {
      bw:this.$route.query.bw,
      wfcName:null,
      wfcRestTime:null,
      wfcRoundOfTime:null,
      wfcImage:null,
      wfcDescription:null,
      wfcExercise:null,
    }
  },
  computed:{
    ...mapFields('workoutCalendar',{
      calendar_route:'calendar_route',
    }),  
    ...mapFields('workoutExercise',[
      'exer_index', 
      'super_index', 
      'wfcType', 
      'warmup', 
      'cooldown', 
      'finisher', 
    ]),
  },
  methods: {
    nextPrevious(){
      this.calendar_route=this.calendar_route+1
      if(this.wfcType=='WARMUP'){
        this.$router.push({ path: 'workoutexerciseset?bw=1' });
      }else if(this.wfcType=='FINISHER'){
        if(this.cooldown!=null){
          this.calendar_route=this.calendar_route-1
          this.wfcType='COOLDOWN'
          this.wfcName=this.cooldown.name;
          this.wfcRestTime=this.cooldown.rest_time;
          this.wfcRoundOfTime=this.cooldown.no_of_rounds;
          this.wfcImage=this.cooldown.image;
          this.wfcDescription=this.cooldown.description;
          this.wfcExercise=this.cooldown.exercises;
        }else{
          this.wfcType='finishworkout'
          this.$router.push({ path: 'workoutexercise?fw=1' });
        }
      }else if(this.wfcType=='COOLDOWN'){
         this.wfcType='finishworkout'
         this.$router.push({ path: 'workoutexercise?fw=1' });
      }
    },
  },
  created(){
    if(this.wfcType=='WARMUP' && this.warmup!=null){
      this.wfcName=this.warmup.name;
      this.wfcRestTime=this.warmup.rest_time;
      this.wfcRoundOfTime=this.warmup.no_of_rounds;
      this.wfcImage=this.warmup.image;
      this.wfcDescription=this.warmup.description;
      this.wfcExercise=this.warmup.exercises;
    }else if(this.wfcType=='FINISHER' && this.finisher!=null){
      this.wfcName=this.finisher.name;
      this.wfcRestTime=this.warmup.rest_time;
      this.wfcRoundOfTime=this.finisher.no_of_rounds;
      this.wfcImage=this.finisher.image;
      this.wfcDescription=this.finisher.description;
      this.wfcExercise=this.finisher.exercises;
    }else if(this.wfcType=='COOLDOWN' && this.cooldown!=null){
      this.wfcName=this.cooldown.name;
      this.wfcRestTime=this.cooldown.rest_time;
      this.wfcRoundOfTime=this.cooldown.no_of_rounds;
      this.wfcImage=this.cooldown.image;
      this.wfcDescription=this.cooldown.description;
      this.wfcExercise=this.cooldown.exercises;
    }
  },
};
</script>

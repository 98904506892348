
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: { 
        dark: true,
        themes: {
            dark: {
              avidocolor: '#52F60C',
              defaultcolor: '#52F60C',
            },
          },
    },
}

export default new Vuetify(opts)
import axios from "axios";
import { getField,updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
     updateField
  },
  getters: {
    getField,
  },

  actions: {
    saveUserWorkout({ dispatch },savedata=0) {
      return new Promise((resolve,reject) => {
          let uw_exercise={}
          let workoutexerciselist=this.state.workoutExercise.exerciseList;
          if(workoutexerciselist!=null){
            let order_count=0;
            for (let workoutexercise of Object.values(workoutexerciselist)) {
              let super_count=0;
              let order_exercise={}  
              for (let wexercise of Object.values(workoutexercise)) {
                let wexercise_sets='';
                let super_exercise={}
                if(wexercise.sets.length>0){
                  let wesets_array=[];
                  for(let wesets of Object.values(wexercise.sets)) {
                    wesets_array.push(wesets.reps+'-'+wesets.weight+'-'+wesets.checkmark_status)
                  }
                  wexercise_sets=wesets_array.join(',')
                }
                
                let restTime=0;
                if(wexercise.rest_time!=undefined && wexercise.rest_time!=0){
                  if (typeof wexercise.rest_time != 'number') {  
                    let restvalue=wexercise.rest_time.split(":");
                    restTime=parseInt(restvalue[0]*60)+parseInt(restvalue[1]);
                  }else if (typeof wexercise.rest_time == 'number') {  
                    restTime=wexercise.rest_time
                  }
                } 

                super_exercise[wexercise.user_workout_exercise_id]=wexercise.exercise_id+'|'+restTime+'|'+wexercise.reps_type+'|'+wexercise.weight_type+'|'+wexercise_sets;
                order_exercise[super_count]=super_exercise;
                super_count++;
              } 
              uw_exercise[order_count]=order_exercise;
              order_count++;
            }
          }

          let api_name='save_user_workout';
          let api_data={
            user_workout_id: this.state.workoutCalendar.current_user_workout_id,
            is_completed: this.state.workoutCalendar.current_user_workout_completed,
            warmup_id: (this.state.workoutExercise.warmup!=null) ? this.state.workoutExercise.warmup.id : 0,
            cooldown_id: (this.state.workoutExercise.cooldown!=null) ? this.state.workoutExercise.cooldown.id : 0,
            finisher_id: (this.state.workoutExercise.finisher!=null) ? this.state.workoutExercise.finisher.id : 0,
            uw_exercise: uw_exercise,
          };
          
          axios({
            method: 'POST',
            url: api_name,
            data: api_data
          })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              if(savedata==1){
                let back_route=1
                if(this.state.workoutCalendar.calendar_route>0){
                  back_route=this.state.workoutCalendar.calendar_route
                } 
                router.go(- back_route);
              }
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 ||resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
  },
};
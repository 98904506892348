<template>
  <div class="profilesettings">
 
    <v-card class="pa-8">
      <v-form
        ref="profilesettings"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >

      <v-row>
        <v-col class="pt-0">
         <v-file-input
              required
              @change="onFileChange"
              v-model="userModel.profile_pic"
              id="image-upload"
              class="d-none"
          ></v-file-input>
          <v-avatar            
            color="avidocolor"
            size="160"
            tile
            class="rounded"
            @click="openUpload"
          >
          <v-icon color="black" x-large :class="(userModel.imagePreviewURL!=null) ? 'd-none' : null">
            mdi-camera
          </v-icon>
           <img :alt="userModel.first_name"  :class="(userModel.imagePreviewURL==null) ? 'd-none' : null" :src="(userModel.imagePreviewURL!=null) ? userModel.imagePreviewURL:'/images/logo.jpg'" @error="userModel.imagePreviewURL='/images/logo.jpg'"> 
          </v-avatar>
        </v-col>
      </v-row>  

      <v-textarea
        counter
        label="Bio / Goals"
        v-model="userModel.bio"
        prepend-icon="mdi-comment-account"
        class="pa-md-4"
        color="avidocolor"
      ></v-textarea>

      <v-row class="pl-md-4">
        <v-col align="left">
            <v-icon>mdi-settings</v-icon> Profile Privacy Setting :
            <v-btn-toggle
              v-model="userModel.privacy_setting"
              tile
              color="avidocolor"
              group
              mandatory
            >
              <v-btn value="PUBLIC">
                PUBLIC
              </v-btn>

              <v-btn value="PRIVATE">
                PRIVATE
              </v-btn>

              <v-btn value="TEAMS">
                TEAMS
              </v-btn>
            </v-btn-toggle>
        </v-col>
      </v-row>  
      
      <v-autocomplete
        v-model="userModel.current_displayskin_id"
        name="userModel.current_displayskin_id"
        :items="userModel.display_skin_options"
        class="pa-md-4"
        label="Select Display Theme"
        prepend-icon="mdi-theme-light-dark"
        color="avidocolor"
        item-text="name"
        item-value="id"
      >
        <template v-slot:item="data">
          <v-list-item-avatar tile size=50>
            <v-img class="rounded"
              :src="
                data.item.logo_image != null
                  ? data.item.logo_image
                  : '/images/logo.jpg'
              "
              @error="data.item.logo_image = '/images/logo.jpg'"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name" class="text-left"></v-list-item-title>
          </v-list-item-content>
           <v-list-item-avatar tile>
              <v-btn :color="data.item.dark_background_color">
                <v-icon :color="data.item.solid_text_color">mdi-format-color-fill</v-icon>
              </v-btn>
           </v-list-item-avatar>
        </template>
      </v-autocomplete>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor"> <v-icon>mdi-content-save</v-icon>save  </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Profilesettings",
  data: () => ({
    valid: true,
  //  imagePreviewURL: null,
  }),
  computed:{
    ...mapFields('accountSettings',{
      userModel:'userModel',
    })
  },
  methods: {
    ...mapActions('accountSettings',{
      getProfileModel:'getProfileModel',
      profileSettingSave:'profileSettingSave',
    }),
    openUpload () {
      document.getElementById('image-upload').click()
    },
    onFileChange(payload) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        this.userModel.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        // console.log(payload)
      } else {
        this.userModel.imagePreviewURL =  null
      }
    },
    onSubmit: function () {
      if (this.$refs.profilesettings.validate()) {
        let userModel = this.userModel;
        this.profileSettingSave(userModel);
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.getProfileModel();
  },
};
</script>
<template>
  <div class="WeekSummary">
    <v-card class="mx-auto" width="100%">
      <v-toolbar color="black">
        <!-- <v-toolbar-title class="avidocolor--text">TRAINING</v-toolbar-title> -->
        <v-spacer></v-spacer>
        
        <v-toolbar-items>
        <v-dialog
          ref="calendardate_dialog"
          v-model="menu2"
          :return-value.sync="calendar_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="calendar_date_text"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              color="avidocolor"
              class="pa-4 pl-0 centered-input"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="calendar_date"
            scrollable
            color="avidocolor black--text"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="avidocolor"
              @click="change_week_summary(calendar_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        </v-toolbar-items>  
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <div class="text-left text-body-1 pt-2">Week Summary</div> 
    <v-list class="pa-2 rounded-lg">  
      <v-row>
          <v-col class="pa-2">
            <span>REPS</span> <br/>
            <v-progress-circular
              :rotate="360"
              :size="80"
              :width="8"
              :value="week_totals.percentage_reps"
              color="avidocolor"
            >
              {{week_totals.percentage_reps}}%
            </v-progress-circular> 
            <br/><div class="text-h5 pa-2">{{week_totals.completed_reps}}</div> 
          </v-col>

          <v-col class="pa-2">
            <span>SETS</span> <br/>
            <v-progress-circular
              :rotate="360"
              :size="80"
              :width="8"
              :value="week_totals.percentage_sets"
              color="avidocolor"
            >
              {{week_totals.percentage_sets}}%
            </v-progress-circular> 
             <br/><div class="text-h5 pa-2">{{week_totals.completed_sets}}</div> 
          </v-col>

          <v-col class="pa-2">
            <span>WORKOUTS</span> <br/>
            <v-progress-circular
              :rotate="360"
              :size="80"
              :width="8"
              :value="week_totals.percentage_workouts"
              color="avidocolor"
            >
              {{week_totals.percentage_workouts}}%
            </v-progress-circular> 
            <br/><div class="text-h5 pa-2"> {{week_totals.completed_workouts}} </div> 
          </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pl-2 pr-2">
        <v-col>
          <span class="avidocolor--text text-h5">Total Weight = {{week_totals.completed_weight}}</span> 
        </v-col>
      </v-row>
    </v-list> 

    <div v-if="current_cycle_id>0" class="text-left text-body-1 pt-2">Cycle</div> 
    <v-list v-if="current_cycle_id>0">
      <v-list-item to='/cycle-details'>
        <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="current_cycle_name" :src="(current_cycle_image!=null)?current_cycle_image:'/images/logo.jpg'" @error="current_cycle_image='/images/logo.jpg'"></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="text-left">
          <v-list-item-title class="avidocolor--text">{{current_cycle_name}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>   
    </v-list>   
    
    <div v-if="calendar_workouts.length==0" class="pt-8 stext-body-1">
      <v-alert color="red">
        User workout Not Set.
      </v-alert>
    </div>  

    <div v-if="calendar_workouts.length>0"  class="text-left text-body-1 pt-2">Workouts</div>
    <v-list  v-if="calendar_workouts.length>0">
       <template v-for="(model, workoutindex) in calendar_workouts"  >
        <v-list-item 
          @click="setCurrentCalendar(model)"
          :key="'list'+workoutindex">

          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="model.workout_name" :src="(model.workout_image!=null) ? model.workout_image : current_workout_image" @error="model.workout_image='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
            <v-list-item-title
              class="align-center"
              v-text="model.workout_name"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon color="avidocolor" v-if="model.workout_completed">
              mdi-check
            </v-icon>
            <span  v-if="!model.workout_completed">Day <br>  <span class="avidocolor--text font-weight-bold mb-1">{{ workoutindex+1 }} </span></span>
          </v-list-item-icon>
          
        </v-list-item>

        <v-divider v-if="workoutindex < calendar_workouts.length - 1"
              :key="workoutindex" ></v-divider>
        </template>
      </v-list>


        <div class="clear"></div>
        <v-footer color="black" fixed padless>
          <v-row
            justify="center"
            no-gutters
          >
            <v-btn  class="my-2 avidocolor--text" text to="/search-library-program">
              <v-icon>mdi-plus-box</v-icon>Program
            </v-btn>

            <v-btn  class="my-2 avidocolor--text" text to="/search-library-cycle">
              <v-icon>mdi-plus-box</v-icon>Cycle
            </v-btn>

            <v-btn  class="my-2 avidocolor--text" text to="/search-library-workout">
              <v-icon>mdi-plus-box</v-icon>Workout
            </v-btn>
          </v-row>
        </v-footer>

  </div>
</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data () {
    return {
      menu2: false,
      get_user_id:this.$route.query.uid,
      get_team_id:this.$route.query.tid,
    }
  },
  computed: {
    ...mapFields("workoutCalendar", {
      calendar_route:'calendar_route',
      current_workout_image:'current_workout_image',
      get_for_user_id:'get_for_user_id',
      get_for_team_id:'get_for_team_id',
      is_workout_editable: "is_workout_editable",
      calendar_workouts: "calendar_workouts",
      calendar_date_text: "calendar_date_text",
      calendar_date: "calendar_date",
      calendar_type: "calendar_type",
      current_workout_id: "current_workout_id",
      current_user_workout_completed: "current_user_workout_completed",
      current_user_cycle_id: "current_user_cycle_id",
      current_user_workout_id: "current_user_workout_id",
      current_workout_name: "current_workout_name",
      current_cycle_id: "current_cycle_id",
      generate_user_workout: "generate_user_workout",
      current_cycle_image: "current_cycle_image",
      current_cycle_name: "current_cycle_name",
      week_totals: "week_totals",
    }),
  },
  methods: {
    ...mapActions("workoutCalendar", {
      getWeekSumaryModel: "getWeekSumaryModel",
    }),
    change_week_summary(cal_date){
      this.$refs.calendardate_dialog.save(cal_date)
      // this.calendar_date=cal_date
      this.getWeekSumaryModel();
    },
    setCurrentCalendar(model){
      this.calendar_route=1
      if(model.workout_id>0){
        this.calendar_type='AVIDO'
        this.calendar_date=model.workout_date
        this.current_workout_id=model.workout_id
        this.current_user_cycle_id=model.user_cycle_id
        this.current_user_workout_completed=model.workout_completed
        this.current_user_workout_id=model.user_workout_id
        this.current_workout_name=model.workout_name
        this.generate_user_workout=(model.user_workout_id>0) ? 0 : 1
        if(model.workout_completed){
          this.$router.push({ path: 'workoutexercise?cw=1' });
        }else{
          this.$router.push({ path: 'workoutexercise' });
        }        
      }
      if(model.library_workout_id>0){
        this.calendar_type='LIBRARY'
        this.calendar_date=model.workout_date
        this.current_workout_id=model.library_workout_id
        this.current_user_cycle_id=model.ul_cycle_id
        this.current_user_workout_completed=model.workout_completed
        this.current_user_workout_id=model.ul_workout_id
        this.current_workout_name=model.workout_name
        this.generate_user_workout=(model.ul_workout_id>0) ? 0 : 1
        if(model.workout_completed){
          this.$router.push({ path: 'workoutexercise?cw=1' });
        }else{
          this.$router.push({ path: 'workoutexercise' });
        }     
      }
    },  

  },

  created() {
    if(this.get_user_id==undefined && this.get_team_id==undefined){
      this.get_for_user_id=localStorage.getItem('user_id');
      this.get_for_team_id=0;
    }
    if(this.get_user_id!=undefined && this.get_team_id!=undefined){
      this.get_for_user_id=this.get_user_id;
      this.get_for_team_id=this.get_team_id;
    }
    this.getWeekSumaryModel();
    this.$vuetify.theme.themes.dark.avidocolor = (typeof(localStorage.getItem("dark_text_color")) != "undefined" && localStorage.getItem("dark_text_color") !== null) ? localStorage.getItem("dark_text_color") : '#52F60C';
  },
};
</script>


<style scoped>
    .centered-input >>> input {
      text-align: center
    }
</style>
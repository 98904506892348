<template>

 <v-card
    class="mx-auto"
    dark
  >
    <v-img
      :src="image_large"
      max-height="250"
      class="grey darken-4"
      @error="image_large='/images/logo.jpg'"
    ></v-img>

    <v-card-title class="py-0">
      Target Muscles
    </v-card-title>
    
    <v-card-text class="text-left align-self-start">
      <v-chip color="black avidocolor--text">
        <strong>Primary: </strong>&nbsp;
        <span class="pl-2 white--text">{{ primary_muscles }}</span>
      </v-chip> &nbsp;
      <v-chip color="black avidocolor--text">
        <strong>Assisting: </strong>&nbsp;
        <span class="pl-2 white--text">{{ secondary_muscles }}</span>
      </v-chip>
    </v-card-text>

    <v-card-title class="text-uppercase">
      <h4>Instructions</h4>
    </v-card-title>

    <v-card outlined>

      <template v-if="position_set_up!=null">
        <v-card-title class="py-0">
          Position:
        </v-card-title>
        <v-card-text>
          <div class="text-left align-self-start subtitle-1" v-for="(ptext, pindex) in position_set_up.split('\n')" :key="pindex">
            {{ ptext }}
          </div>
        </v-card-text>
      </template>

      <template v-if="movement!=null">
        <v-card-title class="py-0">
          Movement:
        </v-card-title>
        <v-card-text >
          <div class="text-left align-self-start subtitle-1" v-for="(mtext, mindex) in movement.split('\n')" :key="mindex">
            {{ mtext }}
          </div>
        </v-card-text>
      </template>

      <template v-if="cue_points!=null">
        <v-card-title class="py-0">
          Coaching Cues:
        </v-card-title>
        <v-card-text>
          <div class="text-left align-self-start subtitle-1" v-for="(ctext, cindex) in cue_points.split('\n')" :key="cindex">
            {{ ctext }}
          </div>
        </v-card-text>
      </template>
    </v-card>

    <v-card-title class="text-uppercase">
      <h4>Exercise Histroy</h4>
    </v-card-title>
    
      <v-card outlined>
        <v-card-title class="py-0">
          One Rep Max:
        </v-card-title>
        <v-card-text>
          <div class="text-left align-self-start subtitle-1">{{ user_1RM }} (estimated)</div>
        </v-card-text>

        <v-card-title class="py-0">
          Exercise Note:
          <v-btn text color="avidocolor" to="/edit-instruction">
            Edit <v-icon>mdi-pencil-box-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-left align-self-start subtitle-1">{{ user_exercise_notes }}</div>
        </v-card-text>

        <template  v-if="user_exercise_history.length>0">
          <v-card-title class="py-0">
            Recent Sets:
          </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <template v-for="(exercise_history) in user_exercise_history">
                  <tr v-for="(item,index) in exercise_history.sets"  :key="item.completion_date">
                    <td :style="(index==(exercise_history.sets.length-1)) ? 'border-bottom: thin solid #52F60C;' : null">{{ exercise_history.completion_date }}</td>
                    <td :style="(index==(exercise_history.sets.length-1)) ? 'border-bottom: thin solid #52F60C;' : null">{{ (item.reps!=null) ? item.reps+' '+exercise_history.reps_type : 0  }}</td>
                    <td :style="(index==(exercise_history.sets.length-1)) ? 'border-bottom: thin solid #52F60C;' : null">{{ (item.weight!=null) ? item.weight+' '+exercise_history.weight_type : 0  }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-card>
  </v-card>

</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ExerciseInstruction",
  computed:{
    ...mapFields('exerciseInstruction',{
      image_large:'image_large',
      primary_muscles:'primary_muscles',
      secondary_muscles:'secondary_muscles',
      position_set_up:'position_set_up',
      movement:'movement',
      cue_points:'cue_points',
      user_1RM:'user_1RM',
      user_exercise_notes:'user_exercise_notes',
      user_exercise_history:'user_exercise_history',
    }),
    ...mapFields('workoutCalendar',{
      is_workout_editable:'is_workout_editable',
    }),  
  },
  methods: {
    ...mapActions("exerciseInstruction", {
      getModel: "getModel",
    }),
  }, 
  created() {
    this.getModel();
  },
}
</script>


// import Router from "../router/index";
import { differenceInMilliseconds, isAfter, subMinutes } from 'date-fns'
import axios from 'axios'
import store from '../store/index';
import router from '../router'

let refreshTimeout = null;
let MinutesBeforeExpiry = null;
let Minutestosubstract = store.getters["user/Minutestosubstract"];
let next_timeout = null;

export {initSession};
export {refreshToken};
export {logout};

function initSession() {
    return new Promise(() => {
 
            let tokenExpiryDate=  new Date(store.getters["user/tokensExpiry"]);

            if(tokenExpiryDate=='Invalid Date'){
                logout()   
                console.log('logout')
            }    
            console.log('tokenExpiryDate-'+tokenExpiryDate)

            if (!tokenExpiryDate) {
                console.log("No token expiry date. user probably never logged in");
              //  return Router.push("/login");
            }
        
            MinutesBeforeExpiry = subMinutes(tokenExpiryDate, Minutestosubstract);//If the token has expired or will expire in the next 30 minutes
            console.log('Minutestosubstract-'+Minutestosubstract)
            console.log('MinutesBeforeExpiry-'+MinutesBeforeExpiry)
            
            const now = new Date();                
            if (isAfter(now, MinutesBeforeExpiry)) { //If the token has expired or will expire in the next 10 minutes
                console.log("Token expired/will expire in the next 2 minutes");
                // return Router.push("/login");
            }
    
            console.log("Token Ok. Expiring at " + tokenExpiryDate);
            next_timeout=differenceInMilliseconds(MinutesBeforeExpiry, now)
            console.log(next_timeout)
            if(next_timeout>=0){
                refreshTimeout = setTimeout(refreshToken,next_timeout);
            }
            
    });
}

function refreshToken(){
    return new Promise((dispatch) => {
        const refresh_token= localStorage.getItem('refresh_token')
      //  store.commit('update_auth_tokens',{});
        axios({
        method: 'POST',
        url: 'refreshtoken',
        headers: {'Refreshtoken': refresh_token}
        })
        .then(resp => {
            const resp_code = resp.status
            if(resp_code==200){
                let tokenData=resp.data
            //    console.log(tokenData)
                store.commit('user/update_auth_tokens',tokenData);

                const tokenExpiryDate=  new Date(store.getters["user/tokensExpiry"]);
                MinutesBeforeExpiry = subMinutes(tokenExpiryDate, Minutestosubstract);
                const now = new Date();
                next_timeout=differenceInMilliseconds(MinutesBeforeExpiry, now)
                console.log(next_timeout)
                refreshTimeout = setTimeout(refreshToken,next_timeout);
               // resolve(resp)
            }else{
                dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass :"error"
                },{ root:true });
            }
        }).catch(error => {
            if(error.response==undefined){
                logout()
            }
        })
    })
}


function logout() {
    return new Promise(() => {  
        store.commit('user/update_auth_tokens',{});
        clearTimeout(refreshTimeout);
        refreshTimeout = null;
        router.push({ path: '/logout' });
    })
}
    

<template>
  <div class="results">

  <v-form
    ref="resultForm"
    v-model="valid"
    lazy-validation
    @submit.prevent="onSubmit"
    method="post"
  >
    <v-row align="center">
      <v-col cols="12" md="3" class="py-0">
        <v-autocomplete
          v-model="exercise_id"
          name="exercise_id"
          :items="avido_exercise_list"
          class="pa-md-4 pb-md-0"
          color="avidocolor"
          label="Start typing to Search Exercise"
          item-text="exercise_name"
          item-value="exercise_id"
        >
          <template v-slot:item="data">
            <v-list-item-avatar tile size=50>
            <v-img class="rounded"
                :src="
                  data.item.exercise_image_thumbnail != null
                    ? data.item.exercise_image_thumbnail
                    : '/images/logo.jpg'
                "
                @error="data.item.exercise_image_thumbnail = '/images/logo.jpg'"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.exercise_name"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>

      </v-col>  

      <v-col cols="12" md="3" class="py-0">
        <v-select
          v-model="time_frame"
          :items="(team_id>0) ? team_time_frame_option : time_frame_option"
          color="avidocolor"
          label="Time Frame"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3" class="py-0" v-if="time_frame=='YYYY-MM'">
        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="month_time"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="month_time"
                label="Select Month"
                prepend-icon="mdi-calendar"
                color="avidocolor"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="month_time"
              type="month"
              color="avidocolor black--text"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="red"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="avidocolor"
                @click="$refs.dialog.save(month_time)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
      </v-col>

      <v-col cols="12"  class="py-0" md="3">
        <v-btn type="submit" large block color="avidocolor"> Search </v-btn>
      </v-col>
    </v-row>
  </v-form>  

    <v-card v-if="y_chart_array.length>0">        
      <div id="chart">
        <apexchart type="bar" :height="result_count*40" :options="chartOptions" :series="y_chart_array"></apexchart>
      </div>
    </v-card>   

  </div>
</template>


<script>
import { mapActions,mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "Result",
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    modal: false,
    valid: false,
  }),
  computed: {
    ...mapFields("results", {
      time_frame: "time_frame",
      month_time: "month_time",
      team_id: "team_id",
      exercise_id: "exercise_id",
      time_frame_option: "time_frame_option",
      team_time_frame_option: "team_time_frame_option",
      chartOptions: "chartOptions",
      y_chart_array:'y_chart_array',
      result_count:'result_count',
    }),
    ...mapFields("getDataList", {
      avido_exercise_list: "avido_exercise_list",
    }),
  },
  methods: {
    ...mapMutations("results", {
      reInitResultModel: "reInitResultModel",
    }),
    ...mapActions("getDataList", {
      getExerciseList: "getExerciseList",
    }),
    ...mapActions("results", {
      getModel: "getModel",
    }),
    onSubmit: function () {
      if (this.$refs.resultForm.validate()) {
        this.getModel();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },

  },
  created() {
    this.reInitResultModel();
    this.getExerciseList();
    this.getModel();
  },
};
</script>


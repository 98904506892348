import Vue from "vue";
import Vuex from "vuex";
import notification from "./modules/notification";
import accountSettings from "./modules/accountSettings";
import trainingPreferences from "./modules/trainingPreferences";
import user from "./modules/user";
import exerciseInstruction from "./modules/exerciseInstruction";
import workoutCalendar from "./modules/workoutCalendar";
import workoutEquipment from "./modules/workoutEquipment";
import workoutExercise from "./modules/workoutExercise";
import workoutExerciseSet from "./modules/workoutExerciseSet";
import team from "./modules/team";
import myTeamTrainers from "./modules/myTeamTrainers";
import client from "./modules/client";
import userProfile from "./modules/userProfile";
import teamProfile from "./modules/teamProfile";
import organization from "./modules/organization";
import organizationProfile from "./modules/organizationProfile";
import teamTrainingPreferences from "./modules/teamTrainingPreferences";
import library from "./modules/library";
import libraryExercise from "./modules/libraryExercise";
import libraryExerciseSet from "./modules/libraryExerciseSet";
import getDataList from "./modules/getDataList";
import search from "./modules/search";
import bottomSearch from "./modules/bottomSearch";
import results from "./modules/results";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    refCount: 0,
    isLoading: false,
    header_title: 'AVIDO',
    component_name: null
  },
  mutations: {
    changeHeaderTitle(state, title) {
      state.header_title = title;
    }, 
    changeComponent(state, componentName) {
      state.component_name = componentName;
    }, 
    loading(state, isLoading) {
      // console.log({isLoading})
      if (isLoading) {
        state.refCount++;
        state.isLoading = true;
      } else if (state.refCount > 0) {
        state.refCount--;
        state.isLoading = (state.refCount > 0);
      }
    }
  },
  modules: {
    notification,
    getDataList,
    user,
    accountSettings,
    trainingPreferences,
    exerciseInstruction,
    workoutCalendar,
    workoutEquipment,
    workoutExercise,
    workoutExerciseSet,
    team,
    myTeamTrainers,
    client,
    userProfile,
    teamProfile,
    organization,
    organizationProfile,
    teamTrainingPreferences,
    library,
    libraryExercise,
    libraryExerciseSet,
    search,
    bottomSearch,
    results,
  },
})

<template>
<div class="BottomSearchExercise">
 <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="black"
        >
          <v-btn
            icon
            dark
            @click="closeDialog()"

          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Search {{header_name}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-data-iterator
          :items="items"      
          :search="search"
          disable-pagination
          hide-default-footer
        >
            <template v-slot:header>
              <v-toolbar
                dark
                color="black"
                class="mb-1"
              >
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                ></v-text-field>
              </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-list>
                  <v-list-item-group
                    :multiple="(result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LADDWARMUP' || result_type=='LADDFINISHER' || result_type=='LADDCOOLDOWN') ? false : true"
                  >
                    <template v-for="(model, index)  in props.items">
                        <v-list-item
                          :key="index"
                          :value="model"
                          active-class="avidocolor--text text--accent-4"
                          @click="selectItem(model)"
                        >                        
                        <template v-slot:default="{ active }">
                          <v-icon  @click="exerciseInfo((result_type=='EXERICSE' || result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='WFCEXERICSE' || result_type=='LEXERICSE' || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LWFCEXERICSE') ? model.exercise_id :  model.id)" class="mr-2" style="vertical-align: baseline;"  color="avidocolor" dark >
                            mdi-information
                          </v-icon>
                          <v-list-item-avatar tile size=50>
                            <v-img class="rounded" 
                              :alt="(result_type=='EXERICSE' || result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='WFCEXERICSE' || result_type=='LEXERICSE' || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LWFCEXERICSE') ? model.exercise_name :  model.name"
                              :src="
                                ((result_type=='EXERICSE' || result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='WFCEXERICSE' || result_type=='LEXERICSE' || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LWFCEXERICSE') ? model.exercise_image_thumbnail :  model.image) != null ? ((result_type=='EXERICSE' || result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='WFCEXERICSE' || result_type=='LEXERICSE' || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LWFCEXERICSE') ? model.exercise_image_thumbnail :  model.image) : '/images/logo.jpg'
                              "
                              @error="(result_type=='EXERICSE' || result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='WFCEXERICSE' || result_type=='LEXERICSE' || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LWFCEXERICSE') ? model.exercise_image_thumbnail :  model.image = '/images/logo.jpg'"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="pl-2 text-left">
                            <v-list-item-title v-text="(result_type=='EXERICSE' || result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='WFCEXERICSE' || result_type=='LEXERICSE' || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LWFCEXERICSE') ? model.exercise_name :  model.name"></v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-checkbox
                              :input-value="active"
                              color="avidocolor"
                            ></v-checkbox>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <v-divider v-if="index < items.length - 1" :key="'d'+index"></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
          </template>
        </v-data-iterator>

        <div class="clear"></div>
        <v-footer color="black" fixed padless>
            <v-row justify="center" no-gutters>

              <v-btn text @click="addExerciseToWorkout(selected_search_ids)" v-if="result_type=='EXERICSE'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
              
              <v-btn text @click="superExerciseToWorkout(selected_search_ids)" v-if="result_type=='SUPEREXERCISE'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
                    
              <v-btn text @click="swapExerciseToWorkout(selected_search_ids)" v-if="result_type=='SWAPEXERCISE'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
                                
              <!-- <v-btn text @click="swapWFCToWorkout(selected_search_ids)" v-if="(result_type=='ADDWARMUP' || result_type=='ADDFINISHER' || result_type=='ADDCOOLDOWN')" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn> -->

              <v-btn text @click="addExerciseToLworkout(selected_search_ids)" v-if="result_type=='LEXERICSE'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
                
              <v-btn text @click="superExerciseToLWorkout(selected_search_ids)" v-if="result_type=='LSUPEREXERCISE'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
                    
              <v-btn text @click="swapExerciseToLWorkout(selected_search_ids)" v-if="result_type=='LSWAPEXERCISE'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
                    
              <v-btn text @click="swapWFCToLWorkout(selected_search_ids)" v-if="(result_type=='LADDWARMUP' || result_type=='LADDFINISHER' || result_type=='LADDCOOLDOWN')" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>

              <v-btn text @click="addWorkoutToLCycle(selected_search_ids)" v-if="result_type=='LADDWORKOUTS'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>

              <v-btn text  @click="addCycleToLProgram(selected_search_ids)" v-if="result_type=='LADDCYCLES'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>

              <v-btn text @click="addExerciseToLwfc(selected_search_ids)" v-if="result_type=='LWFCEXERICSE'" color="avidocolor">
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
            </v-row>
        </v-footer>
          
      </v-card>
    </v-dialog>
  </v-row>
</div>  
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "BottomSearchExercise",
  data: () => ({
    header_name: '',
    search: '',
    dialog: true,
    items: [],
  }),
  computed:{
    ...mapFields('workoutExercise',[ 
      'data_change'
    ]),
    ...mapFields('bottomSearch',{
      avidoExerciseList:'avidoExerciseList',  // make name will be items for search functionality
      selected_search_ids:'selected_search_ids', 
      result_type:'result_type', 
      selected_id:'selected_id', 
    }),
   ...mapFields("library", {
      library_workout_list: "library_workout_list",
      library_cycle_list: "library_cycle_list",
      library_program_list: "library_program_list",
      library_warmup_list: "library_warmup_list",
      library_finisher_list: "library_finisher_list",
      library_cooldown_list: "library_cooldown_list",
    }),
  },  
  methods:{
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
    }),
    ...mapActions("workoutExercise", {
      addExerciseToWorkout: "addExerciseToWorkout",
      swapExerciseToWorkout: "swapExerciseToWorkout",
      superExerciseToWorkout: "superExerciseToWorkout",
    }),  
    ...mapActions("libraryExercise", {
      addExerciseToLworkout: "addExerciseToLworkout",
      superExerciseToLWorkout: "superExerciseToLWorkout",
      swapExerciseToLWorkout: "swapExerciseToLWorkout",
      swapWFCToLWorkout: "swapWFCToLWorkout",
      addWorkoutToLCycle: "addWorkoutToLCycle",
      addCycleToLProgram: "addCycleToLProgram",
      addExerciseToLwfc: "addExerciseToLwfc",
    }),
    closeDialog(){
      this.dialog=false
      this.$router.go(-1) ;
    },
    selectItem(model){
      this.data_change=true
      this.selected_search_ids.push(model)
    }
  },
  created(){
    let result_type=this.result_type
    if(result_type=='EXERICSE' || result_type=='SUPEREXERCISE' || result_type=='SWAPEXERCISE'  || result_type=='WFCEXERICSE' || result_type=='LEXERICSE' || result_type=='LSUPEREXERCISE' || result_type=='LSWAPEXERCISE' || result_type=='LWFCEXERICSE'){
      this.items=this.avidoExerciseList
      this.header_name='Exercise'
    }else if(result_type=='ADDWARMUP' || result_type=='LADDWARMUP'){
      this.items=this.library_warmup_list
      this.header_name='WarmUp'
    }else if(result_type=='ADDFINISHER' || result_type=='LADDFINISHER'){
      this.items=this.library_finisher_list
      this.header_name='Finisher'
    }else if(result_type=='ADDCOOLDOWN' || result_type=='LADDCOOLDOWN'){
      this.items=this.library_cooldown_list
      this.header_name='CoolDown'
    }else if(result_type=='ADDWORKOUTS' || result_type=='LADDWORKOUTS'){
      this.items=this.library_workout_list
      this.header_name='Workout'
    }else if(result_type=='ADDCYCLES' || result_type=='LADDCYCLES'){
      this.items=this.library_cycle_list
      this.header_name='Cycle'
    }else if(result_type=='ADDPROGRAM'){
      this.items=this.library_program_list
      this.header_name='Program'
    }
     
    this.selected_search_ids=[]
  }  
}
</script>


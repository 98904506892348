<template>
  <div class="workoutexercise">

    <v-card color="black">
      <v-container>
          <v-row align="center">
            <v-col cols="5" md="2"  class="py-0">
              <v-avatar            
                color="avidocolor"
                size="160"
                tile
                class="rounded"
              >
              <img :alt="current_workout_name"  :src="(current_workout_image!=null) ? current_workout_image : '/images/bg_gray_logo.jpg'" @error="current_workout_image='/images/bg_gray_logo.jpg'"> 
              </v-avatar>
            </v-col>
            <v-col cols="7" md="10">
              <v-row>   
                <v-col cols="12" md="4" class="py-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Sets</v-list-item-subtitle>
                      <v-list-item-title class="text-h4">{{total_sets}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>       
                <v-col cols="12" md="4" class="py-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Reps</v-list-item-subtitle>
                      <v-list-item-title class="text-h4">{{total_reps}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Weight</v-list-item-subtitle>
                      <v-list-item-title class="text-h4">{{total_weight}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-2 text-justify">
              {{current_workout_description}}
            </v-col>
          </v-row>
        </v-container>  
      </v-card>

      <template v-if="warmup!=null">
        <div class="text-left text-body-1 pt-2">WarmUp</div> 
        <v-list>
          <v-list-item  @click="setWorkoutWFC('WARMUP')">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="warmup.name" :src="(warmup.image!=null)?warmup.image:'/images/logo.jpg'" @error="warmup.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title>{{warmup.name}}</v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar  @click.stop.prevent="(is_workout_editable==1) ? getLibraryModel(['WARMUP','ADDWARMUP',warmup.id]) : null" >
                <v-img alt="swap" src="/images/swap_icon.png"></v-img>
              </v-list-item-avatar>
            </v-list-item-action> -->
          </v-list-item>   
        </v-list>  
      </template>

      <div class="text-left text-body-1 pt-2">Exercise</div> 
        <v-list>
          <template v-for="(exerciseListmodel,index) in exerciseList">
            <template  v-if = "exerciseListmodel.length==1">
              <template v-for="(model,mindex) in exerciseListmodel">
                <v-list-item :key="mindex+'_'+model.user_workout_exercise_id+model.exercise_id" @click="setCurrentExercise(index,mindex,0)" >
                  <v-list-item-avatar tile size=50>
                    <v-img class="rounded" :alt="model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title
                      class="align-center"
                      v-text="model.exercise_name"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action class="align-center ma-0">
                    <v-list-item-action-text>
                      SETS
                      <div class="text-h5">{{model.exercise_set_count}}</div>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </template>

            <template  v-if = "exerciseListmodel.length>1">
              <template v-for="(supermodel,superindex) in exerciseListmodel">
                    
                  <v-list-item  :key="superindex+'_'+supermodel.user_workout_exercise_id+supermodel.exercise_id"
                    @click="setCurrentExercise(index,superindex,0)"
                    >
                    <v-list-item-avatar tile size=50>
                      <v-img class="rounded" alt="avtar_image" :src="supermodel.exercise_image_thumbnail" @error="supermodel.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-left">
                      <v-list-item-title class="align-center"
                        >{{supermodel.exercise_name}}</v-list-item-title
                      >
                    </v-list-item-content>

                    <v-list-item-action class="align-center ma-0">
                      <v-list-item-action-text>
                        SETS
                        <div class="text-h5">{{supermodel.exercise_set_count}}</div>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item  v-if="superindex < exerciseListmodel.length - 1" :key="'icon_'+superindex+supermodel.user_workout_exercise_id+supermodel.exercise_id" style="min-height: 0px !important;">
                    <v-list-item-avatar height="0px"></v-list-item-avatar>
                    <v-list-item-content class="d-block ma-n5 pa-0 text-left">
                      <v-list-item-avatar class="ml-5 mt-0 mb-0 align-center">
                        <v-img alt="superst" src="/images/superset_icon.png"></v-img>
                      </v-list-item-avatar>
                    </v-list-item-content>
                  </v-list-item>

              </template>
            </template>

            <v-divider v-if="index < exerciseList.length - 1"
                :key="index" ></v-divider>
          </template>
        </v-list>

      <template v-if="finisher!=null">
        <div class="text-left text-body-1 pt-2">Finisher</div> 
        <v-list>
          <v-list-item  @click="setWorkoutWFC('FINISHER')">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="finisher.name" :src="(finisher.image!=null)?finisher.image:'/images/logo.jpg'" @error="finisher.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title>{{finisher.name}}</v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar  @click.stop.prevent="(is_workout_editable==1) ? getLibraryModel(['FINISHER','ADDFINISHER',finisher.id]) : null" >
                <v-img alt="swap" src="/images/swap_icon.png"></v-img>
              </v-list-item-avatar>
            </v-list-item-action> -->
          </v-list-item>   
        </v-list>  
      </template>

     <template v-if="cooldown!=null">
        <div class="text-left text-body-1 pt-2">CoolDown</div> 
        <v-list>
          <v-list-item @click="setWorkoutWFC('COOLDOWN')">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="cooldown.name" :src="(cooldown.image!=null)?cooldown.image:'/images/logo.jpg'" @error="cooldown.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title>{{cooldown.name}}</v-list-item-title>
            </v-list-item-content>
           <!--  <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar  @click.stop.prevent="(is_workout_editable==1) ? getLibraryModel(['COOLDOWN','ADDCOOLDOWN',cooldown.id]) : null" >
                <v-img alt="swap" src="/images/swap_icon.png"></v-img>
              </v-list-item-avatar>
            </v-list-item-action> -->
          </v-list-item>   
        </v-list>  
     </template>
 
    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <template v-if="is_workout_editable==1">
            <!----- For AVIDO Workout------->
            <template v-if="calendar_type=='AVIDO'">
              <!----- Workout Exercise Screen ------->
              <template v-if="end_workout==undefined">
                <!----- If Workout is NOT COMPLETED ------->
                <template v-if="is_completed==undefined">
                    <v-btn @click="setCurrentExercise(0,0,1)"  class="my-2 avidocolor--text" text>
                      <v-icon>mdi-plus-box</v-icon>Begin Workout
                    </v-btn>
                    <v-btn to="/editworkoutexercise" class="my-2 avidocolor--text" text>
                      <v-icon>mdi-pencil-box-outline</v-icon>Edit Exercises
                    </v-btn>
                    <v-btn to="/workoutequipment" class="my-2 avidocolor--text" text>
                      <v-icon>mdi-pencil-box-outline</v-icon>Change Equipment
                    </v-btn>
                </template>  
                <!----- If Workout is COMPLETED ------->
                <template v-else>
                  <v-btn @click="setCurrentExercise(0,0,1)"  class="my-2 avidocolor--text" text>
                    <v-icon>mdi-plus-box</v-icon>Update Workout
                  </v-btn>
                  <v-btn to="/editworkoutexercise" class="my-2 avidocolor--text" text>
                    <v-icon>mdi-pencil-box-outline</v-icon>Edit Exercises
                  </v-btn>
                </template>  
              </template> 
              <!----- End Workout Screen ------->
              <template v-else>
                <v-btn @click="complete_workout()"  class="my-2 avidocolor--text" text>
                  <v-icon>mdi-plus-box</v-icon>End Workout
                </v-btn>
              </template> 
            </template>  

            <!----- For LIBRARY Workout------->
            <template v-if="calendar_type=='LIBRARY'">
              <!----- Workout Exercise Screen ------->
              <template v-if="end_workout==undefined">
                <v-btn @click="setCurrentExercise(0,0,1)"  class="my-2 avidocolor--text" text>
                  <v-icon>mdi-plus-box</v-icon>{{ (is_completed==undefined) ? 'Begin' : 'Update' }} Workout
                </v-btn>
                <v-btn to="/editworkoutexercise" class="my-2 avidocolor--text" text>
                  <v-icon>mdi-pencil-box-outline</v-icon>Edit Exercises
                </v-btn>
              </template>  
              <!----- End Workout Screen ------->
              <template v-else>
                <v-btn @click="complete_workout()"  class="my-2 avidocolor--text" text>
                  <v-icon>mdi-plus-box</v-icon>End Workout
                </v-btn>
              </template> 
            </template>  
        </template>  
      </v-row>
    </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WorkoutExercise",
  data () {
    return {
      is_completed:this.$route.query.cw,
      end_workout:this.$route.query.fw,
    }
  },
  computed:{
    ...mapFields('workoutExercise',[
      'exer_index', 
      'super_index', 
      'data_change', 
      'total_reps', 
      'total_sets', 
      'total_weight', 
      'exerciseList',
      'super_exercise_length',
      'current_exer_resttime',
      'totalTime',
      'current_exercise_id',
      'current_exer_name',
      'current_exer_largeimage',
      'current_exer_sets',
      'current_exer_weight_type',
      'wfcType', 
      'warmup', 
      'cooldown', 
      'finisher', 
      'selectedWFC',
      'wfc_type'
    ]),
    ...mapFields('search',{
      result_type:'result_type',
    }), 
    ...mapFields('workoutCalendar',{
      calendar_route:'calendar_route',
      get_exercise:'get_exercise',
      calendar_type:'calendar_type',
      current_workout_name: "current_workout_name",
      current_workout_image: "current_workout_image",
      current_workout_description: "current_workout_description",
      is_workout_editable: "is_workout_editable",
      current_user_workout_completed: "current_user_workout_completed",
    }),
  },
  methods: {

    ...mapActions("workoutExercise", {
      getModel: "getModel",
    }),
    ...mapActions("workoutExerciseSet", {
      saveUserWorkout: "saveUserWorkout"      
    }),
    ...mapActions("library", {
      getLibraryModel: "getLibraryModel",
    }),

    setWorkoutWFC(wfc_type){
      this.wfcType=wfc_type
      this.$router.push({ path: 'workoutwfc' });
    },
    setCurrentExercise(exerIndex,superIndex,bw){

      this.calendar_route=this.calendar_route+1
      this.totalTime=this.exerciseList[exerIndex][superIndex]['rest_time']
      this.current_exer_resttime=this.exerciseList[exerIndex][superIndex]['rest_time']
      this.current_exer_name=this.exerciseList[exerIndex][superIndex]['exercise_name']
      this.current_exercise_id=this.exerciseList[exerIndex][superIndex]['exercise_id']
      this.current_exer_largeimage=this.exerciseList[exerIndex][superIndex]['exercise_large_image']
      this.current_exer_sets=this.exerciseList[exerIndex][superIndex]['sets']
      this.current_exer_weight_type=this.exerciseList[exerIndex][superIndex]['weight_type']
      this.super_exercise_length=this.exerciseList[exerIndex].length
      this.exer_index=exerIndex
      this.super_index=superIndex

      let routeName=null;
      if(bw==1 && exerIndex==0 && superIndex ==0 && this.warmup!=null){
        routeName='workoutwfc'
        this.wfcType='WARMUP'
      }else{
        routeName='workoutexerciseset'
        this.wfcType='exerciseSet'
      }
      if(bw){
        this.$router.push({ path: routeName+'?bw=1' });
      }else{
        let crestTime=this.exerciseList[exerIndex][superIndex]['rest_time']
        if(crestTime>0){
          let result = new Date(null, null, null, null, null, crestTime);
          this.exerciseList[exerIndex][superIndex]['rest_time'] = result.toTimeString().split(' ')[0].substring(3);
        }else{
          this.exerciseList[exerIndex][superIndex]['rest_time'] = '0:00';
        }
        this.$router.push({ path: routeName });
      }   
    }, 
    editUserWorkout(){
      this.$router.push({ path: 'editworkoutexercise' });
    },

    complete_workout(){
      this.current_user_workout_completed=true
      this.saveUserWorkout(1)
    }
  },
  created(){
    if(this.get_exercise){
      this.getModel();
    }
  }, 

  beforeDestroy(){
    let component_name=this.$options.name
    let redirect_location=document.location.pathname
    if(component_name=='WorkoutExercise' && redirect_location!='/workoutexerciseset' && redirect_location!='/bottom-search-exercise'){
      if(this.data_change){
        this.saveUserWorkout()
      }
    }
    if(component_name=='WorkoutExercise' && redirect_location=='/week-summary'){
      this.get_exercise=true
      this.calendar_route=this.calendar_route-1
    }
  },
};
</script>
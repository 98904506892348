<template>
  <div class="Coaches">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-img
            src="/images/coaches_title_image.png"
            class="defaultcolor--text align-center"
          >
            <v-card-title class="d-inline headingclass">
              <p class="text-h4 text-md-h2 font-weight-black">
                STRENGTH & CONDITIONING
              </p>  
              <p class="text-h4 text-md-h2 font-weight-black">
                FOR TEAMS MADE EASY
              </p>
            </v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center"  class="black">
      <v-col class="d-none d-lg-flex d-xl-flex" cols="md-3"></v-col>
      <v-col cols="md-6">
        <div>
          <v-card-title class="text-h6 d-inline">
            <div class="py-2">
              Developing a successful team wide strength & conditioning program
              is challenging even for the most experienced weight room coaches
            </div>
            <v-divider class="defaultcolor"></v-divider>
            <div class="py-2">
              AVIDO simplifies this so coaches can focus on the sport they love
              and still develop the strong, healthy athletes they want
            </div>
          </v-card-title>
        </div>
      </v-col>
      <v-col class="d-none d-lg-flex d-xl-flex" cols="md-3"></v-col>
    </v-row>
  
      <v-row align="center"  class="gray">
        <v-col cols="md-6" class="pa-0">
          <v-img
          :height=imgHeight
          src="/images/coaches_2nd_subsection.png"
          ></v-img>
        </v-col>

        <v-col cols="md-6" class="py-0">
          <v-card-title class="text-h5 text-md-h4 d-inline">
            <div>Start A Team In Under 1 Minute</div>
          </v-card-title>

          <v-row>
              <v-col cols="md-6" class="py-0">
                <div>
                    <v-card-title class="text-h5">
                      1) SELECT THE SPORT
                    </v-card-title>
                    
                    <v-divider class="defaultcolor"></v-divider>

                    <v-card-subtitle class="text-body-1">
                      <div class="pa-3">
                      Choose the sport you want to build a program for 
                      </div>
                    </v-card-subtitle>
                </div>
              </v-col>
               <v-col cols="md-6" class="py-0">
                <div>
                    <v-card-title class="text-h5">
                      2) SELECT EQUIPMENT
                    </v-card-title>
                    
                    <v-divider class="defaultcolor"></v-divider>

                    <v-card-subtitle class="text-body-1">
                      <div class="pa-3">
                      Choose the equipment you want included in workouts
                      </div>
                    </v-card-subtitle>
                </div>
              </v-col>
               <v-col cols="md-6" class="py-0">
                <div>
                    <v-card-title class="text-h5">
                      3) SET # OF WORKOUTS
                    </v-card-title>
                    
                    <v-divider class="defaultcolor"></v-divider>

                    <v-card-subtitle class="text-body-1">
                      <div class="pa-3">
                      Choose the weekly number of workouts 
                      </div>
                    </v-card-subtitle>
                </div>
              </v-col>
              <v-col cols="md-6" class="py-0">
                <div>
                    <v-card-title class="text-h5">
                      4) SET SEASON DATES
                    </v-card-title>
                    
                    <v-divider class="defaultcolor"></v-divider>

                    <v-card-subtitle class="text-body-1">
                      <div class="pa-3">
                      Enter dates your season will start and finish
                      </div>
                    </v-card-subtitle>
                </div>
              </v-col>
            </v-row>

            
        </v-col>
      </v-row>


    <v-parallax
        src="/images/coaches_3rd_subsection.png"
      >
      <v-row align="center">
            <v-col cols="md-6">
              <div>
                  <v-card-title class="text-h5 text-md-h4 d-inline">
                    <div class="py-2">Customized Training For</div>
                    <div class="py-2">Each Individual Athlete</div>
                  </v-card-title>
                  
                  <v-divider class="defaultcolor"></v-divider>

                  <v-card-subtitle class="text-body-1">
                    <div class="pa-3">
                    Coaches set the overall team program so that
                    everyone receives the same base training. 
                    </div>

                    <div class="pa-3">
                    Athletes identify their own position and weaknesses
                    so the workouts they get have exactly what they need. 
                    </div>
                  </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
      </v-parallax>
      
          
   
      <v-row align="center"  class="gray">
        <v-col cols="md-6" class="pa-0">
          <v-img
          :height=imgHeight
          src="/images/coaches 4th sub section.png"
          ></v-img>
        </v-col>

        <v-col cols="md-6">
          <div>
              <v-card-title class="text-h5 text-md-h4 d-inline">
                <div class="py-2">Track Athletes Progress &</div>
                <div class="py-2">Hold Them Accountable</div>
              </v-card-title>

              <v-divider class="defaultcolor"></v-divider>

              <v-card-subtitle class="text-body-1">
                <div class="pa-3">
                Team profiles make it easy to manage your athletes, see who's 
                training, who's slacking and how everyone is improving. 
                When athletes know this they put in an entirely different effort.  
                </div>
              </v-card-subtitle>
          </div>
        </v-col>
      </v-row>
   


    <v-parallax
        src="/images/coaches_5th_subsection.png"
      >
      <v-row align="center">
            <v-col cols="md-6">
              <div>
                  <v-card-title class="text-h5 text-md-h4 d-inline">
                    <div class="py-2">The AVIDO Mission</div>
                  </v-card-title>

                  <v-divider class="defaultcolor"></v-divider>

                  <v-card-subtitle class="text-body-1">
                    <div class="pa-3">
                    Our goal isn't just to build stronger athletes, 
                    it's to redefine what it means to be one. 
                    To build a team culture around hard work. 
                    To make athletes accountable to each other.
                    To create a new definition of TEAM.
                    </div>

                  </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
      </v-parallax>
      
      
    <v-row align="center">
      <v-col cols="12" md="4">
        <img alt="avido logo" height="300" src="/images/bottom_section_logo.png" />
      </v-col>
      <v-col cols="12" md="4">
        <div align="center">
            <v-card-title class="text-h5 text-md-h4 d-inline">
              <div class="py-2">Contact:</div>

              <div class="py-2 text-body-1">
              Email: teams@myavido.com
              </div>

              <div class="py-2 text-body-1">
              (833) - MYAVIDO
              </div>
            </v-card-title>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div align="center">
            <v-card-title class="d-inline">
              <div class="py-2">
                <v-btn class="text-h5" text href="/faq">FAQ?</v-btn>
              </div>
              <div class="py-2">
                <v-btn class="text-h5" text href="/privacypolicy">Privacy Policy</v-btn>
              </div>
              <div class="py-2">
                <v-btn class="text-h5" text href="/termsofuse">Terms Of Service</v-btn>
              </div>
            </v-card-title>
        </div>
      </v-col>
    </v-row>
    <v-divider class="defaultcolor"></v-divider>
    <v-row>
      <v-col>
      &copy; {{ new Date().getFullYear() }} — <strong>Avido LLC. All Rights Reserved.</strong>
      </v-col>
    </v-row>

  </div>
</template>



<script>
export default {
  name: "Coaches",
  data: () => ({    
    imgHeight: 500,
  }),
};
</script>
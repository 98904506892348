import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    time_frame_option: ['ALL','YYYY-MM','MONTHLY','DAILY'],
    team_time_frame_option: ['ALL','YYYY-MM'],
    user_id: localStorage.getItem('user_id'),
    team_id: 0,
    exercise_id: 0,
    time_frame: 'ALL', // ALL,YYYY-MM,MONTHLY,DAILY
    month_time:  new Date().toISOString().substr(0, 7),
    results_data: [],
    chartOptions: [],
    y_chart_array:[],
    result_count:0,
  },

  getters: {
    getField,
  },

  mutations: {
    reInitResultModel(state) {
      state.results_data = []
      state.exercise_id = 0
      state.time_frame = 'ALL'
      state.month_time = new Date().toISOString().substr(0, 7)
    }, 
    reset_chart_data(state){
      state.result_count = 0
      state.chartOptions = {}
      state.y_chart_array = []
    },    
    set_chart_data(state,resp_data){

      state.result_count= resp_data.result.length

      let x_array=[]
      let y_array=[]
      for (let value of Object.values(resp_data.result)) {
        x_array.push(value.title)
        y_array.push(value.amount)
      }

      state.y_chart_array = [{
        data: y_array
      }],
      state.chartOptions= {
        chart: {
          type: 'bar',
          toolbar:{
            show: false
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: [localStorage.getItem("dark_text_color")],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories:  x_array,
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      }
      


      /* state.chartOptions= {
        chart: {
          type: 'bar',
          toolbar:{
            show: false
          },
          events: {
            click: function(chart, w, e) {
               console.log(chart, w, e)
            }
          }
        },
        colors: ['#52F60C'],
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            horizontal: true,
          },

        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false
        },
        tooltip: {
            theme: 'dark'
        },
        xaxis: {
          categories:  x_array,
          labels: {
            style: {
              fontSize: '12px',
              colors: '#ffffff',
            }
          }
        },       
        yaxis: {
          labels: {
            style: {
              show: false
            }
          }
        }
      },
      state.y_chart_array = [{
        data: y_array
      }] */
    },
    updateField,
  },

  actions: {
    getModel({ commit, dispatch }) {      
      commit("reset_chart_data");
      let timeframe=this.state.results.time_frame;
      if(this.state.results.time_frame=='YYYY-MM'){
        timeframe=this.state.results.month_time;
      }
      return new Promise((resolve,reject) => {
        let api_name='get_results' 
        let api_data={
          exercise_id: this.state.results.exercise_id,
          user_id: (this.state.results.team_id>0) ? 0 : this.state.results.user_id,
          team_id: this.state.results.team_id,
          time_frame: timeframe,
        };
        axios({
          method: 'GET',
          url: api_name,
          params:api_data
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit('set_chart_data', response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
  },

};
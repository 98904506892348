<template>
  <div class="teamProfile">

    <v-row align="center" justify="center">
      <v-col>
        <v-avatar size="200">
          <v-img :alt="team_name" :src="(profile_url!=null)?profile_url:'/images/logo.jpg'" @error="profile_url='/images/logo.jpg'"></v-img>
        </v-avatar>
      </v-col>
    </v-row>

    <v-list color="black" class="pa-0">
        <v-list-item>
          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="team_name" :src="(profile_url!=null)?profile_url:'/images/logo.jpg'" @error="profile_url='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="text-left align-self-start">
            <v-list-item-title>
              <span class="font-weight-bold">Goals:</span>
              <span class="text-wrap">
                {{team_description}}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-row>
          <v-col cols="4" class="pa-2">18K Repetations</v-col>
          <v-col cols="4" class="pa-2">2.3m Pounds</v-col>
          <v-col cols="4" class="pa-2">55 Workouts</v-col>
        </v-row>
        <v-divider></v-divider>
      </v-list>

      <template v-if="team_coaches.length > 0">
        <div class="text-left text-body-1 pt-2">Coaches</div>
        <v-list>
          <template v-for="(user, i) in team_coaches">
            <v-list-item
              :key="i"
              @click="getUserProfile(user.id,user.name)"
            >
              <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="user.name" :src="(user.profile_pic!=null)?user.profile_pic:'/images/logo.jpg'" @error="user.profile_pic='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>
              
              <v-list-item-content class="text-left">
                <v-list-item-title v-text="user.name"  class="avidocolor--text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="i < team_coaches.length - 1"
              :key="i+'_'+user.id" ></v-divider>
          </template>  
        </v-list>
      </template>

      <template v-if="team_members.length > 0">
        <div class="text-left text-body-1 pt-2">Team Members</div> 
        <v-list>
          <template v-for="(user, mindex) in team_members">
          <v-list-item
            :key="mindex"
              @click="getUserProfile(user.id,user.name)"
          >

            <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="user.name" :src="(user.profile_pic!=null)?user.profile_pic:'/images/logo.jpg'" @error="user.profile_pic='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            
            <v-list-item-content class="text-left">
              <v-list-item-title v-text="user.name"  class="avidocolor--text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="mindex < team_members.length - 1"
            :key="mindex+'_'+user.id" ></v-divider>
        </template>  
        </v-list>
      </template>

      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
            <v-btn @click="getTeamWorkout(team_id)"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-plus-box</v-icon>Workouts
            </v-btn>

            <v-btn @click="getTeamResult(team_id)"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-plus-box</v-icon>Results
            </v-btn>
 
            <!-- <v-btn  v-if="fromseacrh==1" to="/jointeam"  class="my-2 avidocolor--text" text > -->
            <v-btn to="/jointeam"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-plus-box</v-icon>Join Team
            </v-btn>
        </v-row>
      </v-footer>

  </div>
</template>



<script>
import { mapActions } from "vuex";
import {mapFields  } from "vuex-map-fields";

export default {
  name: "TeamProfile",
  computed:{
    ...mapFields('teamProfile',{
      team_id:'team_id',
      team_name:'team_name',
      profile_url:'profile_url',
      team_description:'team_description',
      team_coaches:'team_coaches',
      team_members:'team_members',
      fromseacrh:'fromseacrh',
    }),
    ...mapFields('results',{
      result_team_id:'team_id',
    }),   
  },
  methods: {
    ...mapActions('teamProfile',{
      getTeamProfileModel:'getTeamProfileModel',
    }),
    getUserProfile(userid,username) {
      this.$router.push({ path: 'user-profile?uid='+userid+'&uname='+username });
    },  
    getTeamWorkout(teamid) {
      this.$router.push({ path: 'week-summary?uid=0&tid='+teamid });
    },
    getTeamResult(teamid) {
      this.result_team_id = teamid;
      this.$router.push({ path: 'results' });
    },
  },
  created() {
    this.getTeamProfileModel();
  },
};
</script>
<template>
  <div class="editInstruction">

    <v-card class="pa-8">
      <v-form
        ref="editnotesform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-textarea
          v-model="user_exercise_notes"
          required
          class="pa-md-4"
          prepend-icon="mdi-comment-account"
          name="user_exercise_notes"
          color="avidocolor"
          :rules="notesRules"
        >
          <template v-slot:label>
            <div>Notes</div>
          </template>
        </v-textarea>

        <v-card-actions>
          <v-btn type="submit"  class="font-weight-bold" large block color="avidocolor">
            <v-icon>mdi-content-save</v-icon>save 
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data: () => ({
    valid: true,
    notesRules: [(v) => !!v || "Notes is required"],
  }),
  name: "updateNotes",
  computed:{
    ...mapFields('exerciseInstruction',{
      user_exercise_notes:'user_exercise_notes',
    }),
  },
  methods: {
    ...mapActions("exerciseInstruction", {
      updateNotes: "updateNotes",
    }),
    onSubmit: function() {
      if (this.$refs.editnotesform.validate()) {
        this.updateNotes();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  }, 
}
</script>


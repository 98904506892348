import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    team_id: 0,
    team_name: null,
    joindialog: null,
    profile_url: null,
    team_description: null,
    total_reps: 0,
    total_weight: 0,
    total_workouts: 0,
    fromseacrh: 0,
    team_coaches: [],
    team_members: [],
    coach_join_code: null,
    athlete_join_code: null,
  },

  getters: {
    getField,
  },

  mutations: {
    setModelState(state, response_data) {
      state.team_name = response_data.team_name
      state.team_description = response_data.description
      state.profile_url = response_data.team_profile_pic
      state.team_coaches = response_data.team_coaches
      state.team_members = response_data.team_members
    },
    resetModelState(state){
      state.athlete_join_code=null
      state.coach_join_code=null
    },
    updateField
  },

  actions: {
    getTeamProfileModel({ commit, dispatch }) {
      if(this.state.teamProfile.team_id>0){
        return new Promise((resolve, reject) => {
          axios({
            method: 'GET',
            url: 'view_team_detail',
            params: {
              team_id: this.state.teamProfile.team_id
            }
          }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelState", response_data);
              resolve(resp)
            }
          })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
      }
    },


    joinTeam({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: 'join_team',
          data: {
            team_id: this.state.teamProfile.team_id,
            coach_join_code: this.state.teamProfile.coach_join_code,
            athlete_join_code: this.state.teamProfile.athlete_join_code,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {

              const success_mesg = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true })

              this.state.teamProfile.joindialog=false
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },


  },

};
<template>
  <div class="workoutexerciseequipment">


    <v-card class="mx-auto" width="100%">
      <v-row class="pl-md-12">
        <v-col
          cols="6"
          sm="2"
          md="2"
          v-for="model in equipmentList"
          :key="model.id"
        >
          <v-checkbox
            :label="model.name"
            :value="model.id"
            color="avidocolor"
            hide-details
            class="mt-0"
            v-model="current_equipments"
            @change="checkboxUpdated($event)"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card>

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
          <v-btn @click="updateEduipment()"   class="my-2 avidocolor--text" text>
            <v-icon>mdi-pencil-box-outline</v-icon>Update Equipments
          </v-btn>
      </v-row>
    </v-footer>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WorkoutEquipment",
  computed:{
    ...mapFields('workoutEquipment',[
      'equipmentList', 
    ]),
    ...mapFields('workoutCalendar',[
      'current_equipments', 
      'generate_user_workout', 
      'get_exercise', 
    ])
  },
  methods: {
    ...mapActions("workoutEquipment", {
      getModel: "getModel",
    }),
/*     ...mapActions("workoutExercise", {
      fromUpdateEquipment: "getModel",
    }), */
    checkboxUpdated(val) {
      if (val === null || val.length === 0) { // Custom checks in this
        console.log('Unchecked')
      } else {
        console.log('Checked')
      }
    },
    updateEduipment(){
      this.generate_user_workout=1
      this.get_exercise=true
      this.$router.go(-1) ;
    }
  },
  created() {
    let parameters = this.$route.query
    this.getModel(parameters);
  },
};
</script>

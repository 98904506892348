<template>
  <div class="login">
<!--     <v-row align="center" justify="center">
      <v-col cols="12" md="4">
        <h1 class="avidocolor--text mb-3">LogIn</h1>
        <v-divider class="white"></v-divider>
      </v-col>
    </v-row> -->

    <v-card class="pa-8">
      <v-form
        ref="loginform"
        v-model="validate"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-text-field
          v-model="email"
          :rules="emailRules"
          required
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          class="pa-md-4"
          prepend-icon="mdi-lock"
          name="password"
          label="Password"
          :type="show ?'text': 'password'"
          :append-icon="show ?'mdi-eye':'mdi-eye-off'" 
          @click:append="show=!show"
          color="avidocolor"
        ></v-text-field>
        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor"> LogIn </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-row align="center" justify="center">
      <v-col>
        <v-btn text color="avidocolor" to="/register"> Register </v-btn> |
        <v-btn text color="avidocolor"> Forget Password ? </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "Login",
  data() {
    return {
      show:false,
      validate: false,
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
           (v && v.length <= 20) || "Password must be less than 20 characters",
      ],
    };
  },
  methods: {
    onSubmit: function () {
      if (this.$refs.loginform.validate()) {
        let email = this.email;
        let password = this.password;
        this.$store
          .dispatch("user/login", { email, password })
          .then(() => this.$router.push("/week-summary"))
          .catch((error) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: error.response.data.errors,
              alertClass: "error",
            });
          });
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  }
};
</script>
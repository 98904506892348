<template>
  <div class="Athletes">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-img
            src="/images/athletes_title_section.jpg"
            class="defaultcolor--text align-center"
          >
            <v-card-title class="d-inline headingclass">
              <p class="text-h4 text-md-h2 font-weight-black">
                THE TRAINING ATHLETES DESERVE
              </p>
            </v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

      <v-row align="center"  class="gray">
        <v-col cols="md-6" class="pa-0">
          <v-img
          :height=imgHeight
          src="/images/athlete_1st_subsection.png"
          ></v-img>
        </v-col>

        <v-col cols="md-6">
          <v-card-title class="text-h5 text-md-h4 d-inline">
            <div class="py-2">AVIDO Delivers Advanced Science Backed</div>
            <div class="py-2">Strength & Conditioning To Your Phone</div>
          </v-card-title>
          
          <v-divider color="#52F60C"></v-divider>

          <v-card-subtitle class="text-body-1">
            <div class="pa-3">
            Take the guess work out of the gym and focus on what matters with a
            sport specific program built to meet the demands of your sport
            and customized to the equipment and time you have available. 
            </div>
          </v-card-subtitle>
        </v-col>
      </v-row>
   

    <v-parallax
        dark
        src="/images/bg_athletes_2th_subsection.png"
      >
      <v-row align="center">
            <v-col cols="md-6">
              <div>
                    <v-card-title class="text-h5 text-md-h4 d-inline">
                      <div class="py-2">The Training You Need Today</div>
                      <div class="py-2">To Be A Champion Tomorrow</div>
                    </v-card-title>
                    
                    <v-divider class="defaultcolor"></v-divider>

                    <v-card-subtitle class="text-body-1">
                      <div class="pa-3">
                      AVIDO programs are built based on when your season starts. This
                      maximizes the effectiveness of Off-Season and Pre-Season training
                      to ensure you are in peak condition when it matters the most.
                      </div>

                      <div class="pa-3">
                      AVIOD's In-Season training provides the right amount of work to stay
                      strong but still recover so you countinue to perform at your best.
                      </div>
                    </v-card-subtitle>
                </div>
            </v-col>
          </v-row>
      </v-parallax>

   
      <v-row align="center"  class="gray">
        <v-col cols="md-6" class="pa-0">
          <v-img
          :height=imgHeight
          src="/images/athlete_3rd_subsection.png"
          ></v-img>
          </v-col>

        <v-col cols="md-6">
          <div>
              <v-card-title class="text-h5 text-md-h4 d-inline">
                <div class="py-2">The Knowledge To Train With Confidence</div>
                <div class="py-2">The Motivation To Stay On Track</div>
              </v-card-title>

              <v-divider class="defaultcolor"></v-divider>

              <v-card-subtitle class="text-body-1">
                <div class="pa-3">
                Over 1000 exercises with simple instructions, coaching cues and
                detailed videos provide the variety to keep your training fresh.   
                </div>

                <div class="pa-3">
                Weekly training summaries that show total sets, reps and weight,
                along with progress charts for each exercise keep you focused. 
                </div>
              </v-card-subtitle>
          </div>
        </v-col>
      </v-row>
   

      <v-parallax
          src="/images/bg_athletes_4th_subsection.png"
        >
        <v-row align="center">
              <v-col cols="md-6">
              <div>
                    <v-card-title class="text-h5 text-md-h4 d-inline">
                      <div class="py-2">Join Teams And Get</div>
                      <div class="py-2">Coaching From Trainers</div>
                    </v-card-title>

                    <v-divider class="defaultcolor"></v-divider>

                    <v-card-subtitle class="text-body-1">
                      <div class="pa-3">
                      Join teams through AVIDO and train on the exact
                      programs your coaches set up.
                      </div>

                      <div class="pa-3">
                      Complete the same workouts as your teammates 
                      to see how your progress compares.
                      </div>
                    </v-card-subtitle>
                </div>
              </v-col>
            </v-row>
        </v-parallax>


    <v-row align="center">
      <v-col cols="12" md="4">
        <img alt="avido logo" height="300" src="/images/bottom_section_logo.png" />
      </v-col>
      <v-col cols="12" md="4">
        <div align="center">
            <v-card-title class="text-h5 text-md-h4 d-inline">
              <div class="py-2">Contact:</div>

              <div class="py-2 text-body-1">
              Email: teams@myavido.com
              </div>

              <div class="py-2 text-body-1">
              (833) - MYAVIDO
              </div>
            </v-card-title>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div align="center">
            <v-card-title class="d-inline">
              <div class="py-2">
                <v-btn class="text-h5" text href="/faq">FAQ?</v-btn>
              </div>
              <div class="py-2">
                <v-btn class="text-h5" text href="/privacypolicy">Privacy Policy</v-btn>
              </div>
              <div class="py-2">
                <v-btn class="text-h5" text href="/termsofuse">Terms Of Service</v-btn>
              </div>
            </v-card-title>
        </div>
      </v-col>
    </v-row>
    <v-divider class="defaultcolor"></v-divider>
    <v-row>
      <v-col>
      &copy; {{ new Date().getFullYear() }} — <strong>Avido LLC. All Rights Reserved.</strong>
      </v-col>
    </v-row>

  </div>
</template>



<script>
export default {
  name: "Athletes",
  data: () => ({    
    imgHeight: 500,
  }),
};
</script>